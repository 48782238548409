export const RQ_UPLOAD_DOCUMENT_API = {
  url: 'document/upload',
  method: 'POST',
  auth: true,
};

export const RQ_DOCUMENT_THUMBNAIL_API  = {
  url: 'api/v1/document/<%= id %>/thumbnail',
  method: 'GET',
  auth: true,
};

export const RQ_DOCUMENT_VIEW_API  = {
  url: 'api/v1/document/<%= id %>/view',
  method: 'GET',
  auth: true,
};

export const RQ_DOCUMENT_LOOKUP_API  = {
  url: 'api/v1/document/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_DOCUMENT_DOWNLOAD_API = {
  url: 'document/download/<%= id %>',
  method: 'GET',
  auth: true,
};
