import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_CHEMIST_API,
  RQ_GET_CHEMIST_FIND_BY_ID,
  RQ_GET_CHEMIST_MASTER_UPDATION_API,
  RQ_PUT_CHEMIST_DEACTIVATE_BY_DOCTOR_ID,
  RQ_UPDATE_CHEMIST_API,
} from '@app/reactQuery/apis/constants';

export const RQUsePutChemistDeactivateByChemistId = async ({
  certificate,
  signal,
  chemistId,
}) => {
  return rqCreateRequest(
    RQ_PUT_CHEMIST_DEACTIVATE_BY_DOCTOR_ID,
    null,
    certificate,
    {chemistId: chemistId},
    signal,
  );
};
export const RQUseGetChemistMasterUpdationData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_chemist_master_updation_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CHEMIST_MASTER_UPDATION_API,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetChemistFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_chemist_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CHEMIST_FIND_BY_ID,
        null,
        certificate,
        {chemistId: id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPostCreateChemist = ({chemist, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_CHEMIST_API,
    JSON.stringify({...chemist}),
    certificate,
    null,
    signal,
  );
};export const RQPostUpdateChemist = ({chemist, certificate, id, signal}) => {
  return rqCreateRequest(
    RQ_UPDATE_CHEMIST_API,
    JSON.stringify({...chemist, id:id}),
    certificate,
    null,
    signal,
  );
};
