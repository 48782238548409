export const RQ_GET_CHEMIST_MASTER_UPDATION_API = {
  url:'chemist/list/<%= locationId %>',
  method: 'GET',
  auth: true,
};
export const RQ_PUT_CHEMIST_DEACTIVATE_BY_DOCTOR_ID = {
  url: 'chemist/deactivate/<%= chemistId %>',
  method: 'PUT',
  auth: true,
};
export const RQ_GET_CHEMIST_BY_TYPE_API = {
  url:'systemlov/by-type/CHEMIST_CUSTOMER_TYPE',
  method:'GET',
  auth:true,
};
export const RQ_GET_DOCTOR_BY_TYPE_API = {
  url:'doctor/dropdown-value/<%= locationId %>',
  method:'GET',
  auth:true,
};
export const RQ_CREATE_CHEMIST_API = {
  url: 'chemist/save-chemist',
  method: 'POST',
  auth: true,
};
export const RQ_UPDATE_CHEMIST_API = {
  url: 'chemist/save-chemist',
  method: 'POST',
  auth: true,
};

export const RQ_GET_CHEMIST_FIND_BY_ID = {
  url: 'chemist/restore/<%= chemistId %>',
  method: 'GET',
  auth: true,
};
