import React, {Suspense, lazy} from 'react';
import {ROUTE_RCPA_TREND_REPORT} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const RcpaTrendReport = lazy(() => import('../../../screens/RcpaTrendReport')
  .then(module => ({default: module.RcpaTrendReport})));

const RcpaTrendReportNavigation = [
  {
    path: ROUTE_RCPA_TREND_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <RcpaTrendReport />
      </Suspense>
    ),
  },
];

export default RcpaTrendReportNavigation;
