import React, {Suspense, lazy} from 'react';
import {ROUTE_REVIEW_DASHBOARD} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const ReviewDashboard = lazy(() => import('../../../screens/ReviewDashboard')
  .then(module => ({default: module.ReviewDashboard})));

const ReviewDashboardNavigation = [
  {
    path: ROUTE_REVIEW_DASHBOARD,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReviewDashboard />
      </Suspense>
    ),
  },
];

export default ReviewDashboardNavigation;
