export const RQ_POST_UNLOCK_EXPENCE_BY_EMPLOYEE_CODE_YEAR_MONTH_API = {
  url: 'report-unlock/expense/<%= employeeCode %>/<%= yyyyMm %>',
  method: 'POST',
  auth: true,
};

export const RQ_GET_EXPENCE_LIST_BY_YEAR_MONTH_API = {
  url:'report-unlock/expense/list/<%= yyyyMm  %>',
  method:'GET',
  auth:true,
};
