export const RQ_GET_EMPLOYE_LOCATION_API = {
  url: 'location/hierarchy-filter/<%= locationId %>',
  method: 'GET',
  auth: true,

};

export const RQ_GET_EMPLOYEE_MY_TEAM_LOCATION_BY_LOCATIONID_API = {
  url: 'employee/my-team/<%= locationId %>',
  method: 'GET',
  auth: true,
};
