export const RQ_GET_DIVISION_LIST_API = {
  url: 'division/list',
  method: 'GET',
  auth: true,
};

export const RQ_GET_DIVISION_FIND_BY_ID = {
  url: 'division/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_CREATE_DIVISION_API = {
  url: 'division/create',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_DIVISION_ALL_FIELDS_API = {
  url: 'division/update',
  method: 'PUT',
  auth: true,
};
