import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_CHEMIST_APPROVAL, ROUTE_VIEW_CHEMIST_APPROVAL} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const ChemistApproval = lazy(() =>
  import('../../../screens/ChemistApproval')
    .then(module => ({default: module.ChemistApproval})));

const ViewChemist = lazy(() =>
  import('../../../screens/ChemistApproval')
    .then(module => ({default: module.ViewChemist})));

const ChemistApprovalNavigation = [
  {
    path: ROUTE_CHEMIST_APPROVAL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ChemistApproval />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_CHEMIST_APPROVAL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewChemist />
      </Suspense>
    ),
  },
];

export default ChemistApprovalNavigation;
