import React from 'react';
import PropTypes from 'prop-types';
import {Form, Select} from 'antd';
import {connect} from 'react-redux';
import {RQCommonListData} from '@app/reactQuery/apis/rqUIAPI';
import {SystemSelectNames} from '@app/components/common/FormBuilder/attributeTypes/system/SystemSelectEntityEnum';
import {selectAuth} from '@app/redux/selectors';

const MultiSelectDependentObjectAttribute = ({auth, attributeMetadata, formValues}) => {
  const form = Form.useFormInstance();
  const parentValue = formValues[attributeMetadata?.input_parent_id];
  const {select_entity} = attributeMetadata;
  const selectEnum = SystemSelectNames[select_entity];
  const {data = []} =
        selectEnum?.name
          ? RQCommonListData(
            {
              misc: {...selectEnum?.misc || {}},
              name: selectEnum?.name,
              api: selectEnum?.api,
              token: auth?.token,
              userId: auth?.user?.id,
              locationId: auth?.user?.location?.id,
            })
          : {data: []};
  const isVertical = attributeMetadata?.layout === 'vertical';

  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      wrapperCol={{
        span: isVertical
          ? ((isVertical && attributeMetadata?.vertical_value_col_span)
            ? attributeMetadata?.vertical_value_col_span
            : 24)
          : 20,
      }}
      labelCol={{
        span: isVertical ? 24 : 4,
        ...((isVertical && attributeMetadata?.vertical_label_height)
          ? {style: {height: attributeMetadata?.vertical_label_height}}
          : {}),
      }}
      rules={parentValue?.id === attributeMetadata.input_parent_value ? attributeMetadata.validation_rules : null}
    >
      <Select
        allowClear={true}
        mode={'multiple'}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch={attributeMetadata.show_search}
        placeholder={attributeMetadata?.placeholder}
        disabled={!attributeMetadata.editable}
        onChange={obj => {
          form.setFieldValue(attributeMetadata.id + '_label', data.filter(val => val.id === obj)[0]?.name);
          return form.setFieldValue(attributeMetadata.id, obj);
        }
        }>
        {data?.map((object, index) => (
          <Select.Option
            value={object.id}
            key={index}
          >
            {object?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

MultiSelectDependentObjectAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    child_id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    input_options: PropTypes.array,
    input_parent_id: PropTypes.string.isRequired,
    input_parent_value: PropTypes.string.isRequired,
    select_entity: PropTypes.string.isRequired,
    show_search: PropTypes.bool,
    layout: PropTypes.string,
    vertical_value_col_span: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical_label_height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiple: PropTypes.bool,
  }).isRequired,
  auth: PropTypes.shape({
    token: PropTypes.string.isRequired,
    user: PropTypes.any.isRequired,
  }).isRequired,
  formValues: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(MultiSelectDependentObjectAttribute);
