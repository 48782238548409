import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_BRAND_DETAILING_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const BrandDetailing = lazy(() =>
  import('../../../screens/BrandDetailing')
    .then(module => ({default: module.BrandDetailing})));

const BrandDetailingNavigation = [
  {
    path: ROUTE_BRAND_DETAILING_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <BrandDetailing />
      </Suspense>
    ),
  },
];

export default BrandDetailingNavigation;
