import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_LEAVE_DETAILS_API, RQ_SAVE_LEAVE_HO_REMARKS} from '@app/reactQuery/apis/constants';

export const RQUseGetLeaveDetailsData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_leave_details_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate,  year, employeeCode} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LEAVE_DETAILS_API,
        null,
        certificate,
        {employeeCode:employeeCode,
          year:year,
        },
        signal,
      );
    },
    ...options,
  });
};

export const RQSaveHoRemarksForLeave = ({certificate, leaveId, remarks, signal}) => {
  return rqCreateRequest(
    RQ_SAVE_LEAVE_HO_REMARKS,
    null,
    certificate,
    {leaveId:leaveId, remarks:remarks},
    signal,
  );
};
