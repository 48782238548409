import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_CHEMIST_VISIT_SUMMARY_API} from '@app/reactQuery/apis/constants';

export const RQUseGetChemistVisitSummaryData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_chemist_visit_summary_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, startDate, endDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CHEMIST_VISIT_SUMMARY_API,
        null,
        certificate,
        {
          locationId: locationId,
          fromYearMonthDate: startDate,
          toYearMonthDate: endDate,
        },
        signal,
      );
    },
    ...options,
  });
};
