import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_PRODUCT, ROUTE_PRODUCT, ROUTE_UPDATE_PRODUCT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Product = lazy(() =>
  import('../../../screens/Product')
    .then(module => ({default: module.Product})));

const UpdateProduct = lazy(() =>
  import('../../../screens/Product')
    .then(module => ({default: module.UpdateProduct})));

const CreateProduct = lazy(() =>
  import('../../../screens/Product')
    .then(module => ({default: module.CreateProduct})));

const ProductNavigation = [
  {
    path: ROUTE_PRODUCT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Product />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_PRODUCT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateProduct />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_PRODUCT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateProduct />
      </Suspense>
    ),
  },
];

export default ProductNavigation;
