import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_CHEMIST_VISIT_SUMMARY} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const ChemistVisitSummary = lazy(() =>
  import('../../../screens/ChemistVisitSummary')
    .then(module => ({default: module.ChemistVisitSummary})));

const ChemistVisitSummaryNavigation = [
  {
    path: ROUTE_CHEMIST_VISIT_SUMMARY,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ChemistVisitSummary />
      </Suspense>
    ),
  },
];

export default ChemistVisitSummaryNavigation;
