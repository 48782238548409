import React from 'react';
import PropTypes from 'prop-types';
import {DatePicker, Form} from 'antd';

const DateAttribute = ({attributeMetadata}) => {

  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      rules={attributeMetadata.validation_rules}
    >
      <DatePicker
        format={attributeMetadata.date_format}
        onChange={(date, dateString) => dateString}
        disabled={!attributeMetadata.editable}
      />
    </Form.Item>
  );
};

DateAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    date_format: PropTypes.string,
  }).isRequired,
};

export default DateAttribute;
