export const RQ_POST_ADD_SPECIAL_FARE_BY_EMPLOYEE_CODE_YEAR_MONTH_API = {
  url: 'expense/insert-special-fare/<%= employeeCode %>/<%= yyyyMm %>',
  method: 'POST',
  auth: true,
};

export const RQ_GET_ADD_SPECIAL_FARE_BY_YEAR_MONTH_API = {
  url: 'expense/get-special-fare/<%= yyyyMm %>',
  method: 'GET',
  auth: true,
};
