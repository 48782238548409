import {ROUTE_BRAND_SPECIALITY_REPORT} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';

const BrandSpeciality = lazy(() =>
  import('../../../screens/BrandWiseSpecialityReport')
    .then(module => ({default: module.BrandWiseSpecialityReport})));

const BrandWiseSpecialityReportNavigation = [
  {
    path: ROUTE_BRAND_SPECIALITY_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <BrandSpeciality />
      </Suspense>
    ),
  },
];

export default BrandWiseSpecialityReportNavigation;
