import React from 'react';
import PropTypes from 'prop-types';
import {logoutStart} from '@app/redux/actions';
import {connect} from 'react-redux';
import {selectAuth} from '@app/redux/selectors';
import {Button, Modal} from 'antd';
import './LogoutButton.less';
import {PRIMARY_300} from '@app/style/GlobalColors';
import {LogoutOutlined} from '@ant-design/icons';

const {confirm} = Modal;

const LogoutButton = ({auth, handleLogoutStart}) => {
  const showConfirm = () => {
    confirm({
      title: 'Are you sure you want to logout?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      okButtonProps: {style: {backgroundColor: PRIMARY_300}},
      onOk: () => handleLogoutStart(auth),
    });
  };

  return (
    <>
      <Button className={'w-full'} type={'link'} onClick={showConfirm}>
        <LogoutOutlined style={{lineHeight: 'inherit'}} />
      Logout
      </Button>
    </>
  );
};

LogoutButton.propTypes = {
  auth: PropTypes.object.isRequired,
  handleLogoutStart: PropTypes.func.isRequired,
};

const actions = {
  handleLogoutStart: logoutStart,
};

const mapStateToProps = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapStateToProps, actions)(LogoutButton);
