import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_PRODUCT_API,
  RQ_GET_PRODUCT_API,
  RQ_GET_PRODUCT_DATA_BY_BRAND_API,
  RQ_GET_PRODUCT_FIND_BY_ID,
  RQ_UPDATE_PRODUCT_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants/rqProductAPIConstants';

export const RQUseGetProductData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_state_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_PRODUCT_API,
        null,
        certificate,
        null,
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetProductDataByBrandId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_product_data_by_brand_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, brandId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_PRODUCT_DATA_BY_BRAND_API,
        null,
        certificate,
        {brandId: brandId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetProductFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_product_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_PRODUCT_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutCreateProduct = ({product, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_PRODUCT_API,
    JSON.stringify({...product}),
    certificate,
    null,
    signal,
  );
};

export const RQPutProductUpdateAllField = async ({
  product,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_PRODUCT_ALL_FIELDS_API,
    JSON.stringify({...product, id:id}),
    certificate,
    null,
    signal,
  );
};
