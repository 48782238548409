import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_MISSED_CALL_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const MissedCallReports = lazy(() =>
  import('../../../screens/MissedCallReports')
    .then(module => ({default: module.MissedCallReports})));

const MissedCallReportsNavigation = [
  {
    path: ROUTE_MISSED_CALL_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MissedCallReports />
      </Suspense>
    ),
  },
];

export default MissedCallReportsNavigation;
