import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_CONSOLIDATED_REPORTS_} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const ConsolidatedReports = lazy(() =>
  import('../../../screens/ConsolidatedReports')
    .then(module => ({default: module.ConolidatedReports})));

const ConsolidatedReportsNavigation = [
  {
    path: ROUTE_CONSOLIDATED_REPORTS_,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ConsolidatedReports />
      </Suspense>
    ),
  },
];

export default ConsolidatedReportsNavigation;
