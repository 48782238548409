import {lazy} from 'react';
import {ROUTE_SALES_VS_TGT_REPORT} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import React, {Suspense} from 'react';
import {Spin} from 'antd';

const SalesVsTgtReport = lazy(() => import('../../../screens/SalesVsTgtReport')
  .then(module => ({default: module.SalesVsTgtReport})));

const SalesVsTgtReportNavigation = [
  {
    path: ROUTE_SALES_VS_TGT_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SalesVsTgtReport />
      </Suspense>
    ),
  },
];

export default SalesVsTgtReportNavigation;
