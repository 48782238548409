import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_RXBER_REPORTS_API} from '@app/reactQuery/apis/constants';

export const RQUseRxberReportsData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_rxber_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDateStr, toDateStr} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_RXBER_REPORTS_API,
        null,
        certificate,
        {divisionId: divisionId,
          fromDateStr:fromDateStr,
          toDateStr:toDateStr},
        signal,
      );
    },
    ...options,
  });
};
