import React from 'react';
import {WelcomeHeader} from './WelcomeHeader';
import {Col, Row} from 'antd';
import './HeaderLayout.less';
import {HeaderRightButton} from '@app/screensComponents/HomeScreen/MainHome/HeaderRightButton';

const HeaderLayout = () => {
  return (
    <Row direction={'horizontal'}
      gutter={[10]}
      className={'main-header-container'}>
      <Col span={10}>
        <WelcomeHeader />
      </Col>
      <Col>
        <HeaderRightButton />
      </Col>
    </Row>
  );
};

export default HeaderLayout;
