import React from 'react';
import {
  DateAttribute,
  EmailAttribute,
  HiddenAttribute,
  NumberAttribute,
  RadioButtonAttribute,
  SelectAttribute,
  TextAreaAttribute,
  TextAttribute,
} from './attributeTypes';
import PropTypes from 'prop-types';
import {CHECKBOX, DATE, EMAIL, HIDDEN, NUMBER, RADIO, SELECT, TEXT, TEXTAREA} from './attributeTypes/AttributeTypeEnum';
import {Row} from 'antd';
import {CheckboxAttribute} from '@app/components/common/FormBuilder/attributeTypes/standard/CheckboxAttribute';

const StandardAttributeCreator = ({attributeMetadata}) => {
  let formElement;

  switch (attributeMetadata.input_type) {
    case TEXT:
      formElement = (<TextAttribute attributeMetadata={attributeMetadata} />);
      break;
    case EMAIL:
      formElement = (<EmailAttribute attributeMetadata={attributeMetadata} />);
      break;
    case HIDDEN:
      formElement = (<HiddenAttribute attributeMetadata={attributeMetadata} />);
      break;
    case DATE:
      formElement = (<DateAttribute attributeMetadata={attributeMetadata} />);
      break;
    case NUMBER:
      formElement = (<NumberAttribute attributeMetadata={attributeMetadata} />);
      break;
    case CHECKBOX:
      formElement = (<CheckboxAttribute attributeMetadata={attributeMetadata} />);
      break;
    case SELECT:
      formElement = (<SelectAttribute attributeMetadata={attributeMetadata} />);
      break;
    case RADIO:
      formElement = (<RadioButtonAttribute attributeMetadata={attributeMetadata} />);
      break;
    case TEXTAREA:
      formElement = (<TextAreaAttribute attributeMetadata={attributeMetadata} />);
      break;
    default:
      formElement = (
        <Row justify={'center'}>
          {attributeMetadata.input_type} - {attributeMetadata.label}
        </Row>
      );
  }

  return formElement;
};

StandardAttributeCreator.propTypes = {
  attributeMetadata: PropTypes.shape({
    input_type: PropTypes.string.isRequired,
  }).isRequired,
};

export default StandardAttributeCreator;
