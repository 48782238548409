import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_HOSPITAL_RCPA_REPORTS} from '@app/reactQuery/apis/constants';

export const RQUseGetHospitalRCPAReportsData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_hospital_rcpa_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, startDate, endDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_HOSPITAL_RCPA_REPORTS,
        null,
        certificate,
        {
          divisionId: divisionId,
          fromYearMonthDate: startDate,
          toYearMonthDate: endDate,
        },
        signal,
      );
    },
    ...options,
  });
};
