import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_LEAVE_BALANCE_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const LeaveBalanceReport = lazy(() =>
  import('../../../screens/LeaveBalanceReport')
    .then(module => ({default: module.LeaveBalanceReport})));

const LeaveBalanceReportNavigation = [
  {
    path: ROUTE_LEAVE_BALANCE_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <LeaveBalanceReport />
      </Suspense>
    ),
  },
];

export default LeaveBalanceReportNavigation;
