import {rqCreateRequest} from './rqHttpsUtils';
import {RQ_USER_CHANGE_PASSWORD_API, RQ_USER_FORGOT_PASSWORD_API, RQ_USER_LOGIN_API} from './constants';

export const RQUserLoginAPI = ({username, password, signal}) => {
  return rqCreateRequest(
    RQ_USER_LOGIN_API,
    JSON.stringify({
      username: username,
      password: password,
    }),
    null,
    null,
    signal,
  );
};

export const RQUserForgotPasswordAPI = ({username, signal}) => {
  return rqCreateRequest(
    RQ_USER_FORGOT_PASSWORD_API,
    JSON.stringify({
      username: username,
    }),
    null,
    null,
    signal,
  );
};

export const RQUserChangePasswordAPI = ({password,   certificate, signal}) => {
  return rqCreateRequest(
    RQ_USER_CHANGE_PASSWORD_API,
    JSON.stringify({...password}),
    certificate,
    null,
    signal,
  );
};
