import PropTypes from 'prop-types';
import {RQUseGetDocumentInfo} from '@app/reactQuery/apis/rqDocumentAPI';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import {Modal, Upload} from 'antd';
import {buildHttpDocumentViewUrl, buildHttpUrl} from '@app/utils';
import {template} from 'lodash';
import {RQ_DOCUMENT_THUMBNAIL_API, RQ_DOCUMENT_VIEW_API} from '@app/reactQuery/apis/constants/rqDocumentAPIConstants';

const {confirm} = Modal;

const FilePreview = ({auth, documentId, removeDocument}) => {
  const {data} = RQUseGetDocumentInfo({
    tokenData: auth?.tokenData,
    id: documentId,
  });

  if (!data) {
    return null;
  }

  const onDocumentRemove = file => {
    return new Promise((resolve, reject) => {
      confirm({
        title: `Are you sure you want to delete file '${data.documentName + data.extension}' ?`,
        onOk: () => {
          resolve(true);
          removeDocument(documentId);
        },
      });
    });
  };

  return (
    <Upload
      listType={'picture'}
      defaultFileList={[
        {
          uid: documentId,
          name: data.documentName + data.extension,
          status: 'done',
          url: buildHttpDocumentViewUrl({
            url: buildHttpUrl(template(RQ_DOCUMENT_VIEW_API.url)({id: documentId})),
            token: auth?.tokenData?.access_token,
          }),
          thumbUrl: buildHttpDocumentViewUrl({
            url: buildHttpUrl(template(RQ_DOCUMENT_THUMBNAIL_API.url)({id: documentId})),
            token: auth?.tokenData?.access_token,
          }),
        },
      ]}
      onRemove={onDocumentRemove}
    >
    </Upload>
  );
};

FilePreview.propTypes = {
  auth: PropTypes.any,
  documentId: PropTypes.string,
  removeDocument: PropTypes.func,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};
export default connect(mapState)(FilePreview);
