import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_RXBER_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const RxberReport = lazy(() =>
  import('../../../screens/RxberReport')
    .then(module => ({default: module.RxberReport})));

const RxberReportNavigation = [
  {
    path: ROUTE_RXBER_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <RxberReport />
      </Suspense>
    ),
  },
];

export default RxberReportNavigation;
