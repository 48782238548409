import {RQ_GET_OVERVIEW_REPORTS_USER_API} from '@app/reactQuery/apis/constants';
import {rqCreateRequest} from '@app/reactQuery/apis/rqHttpsUtils';

export const RQUsePostOverviewData = async ({
  overviewReport,
  certificate,
  signal,
}) => {
  return rqCreateRequest(
    RQ_GET_OVERVIEW_REPORTS_USER_API,
    JSON.stringify({...overviewReport}),
    certificate,
    null,
    signal,
  );
};
