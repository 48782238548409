import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_MY_ASSEST} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const MyAsset = lazy(() =>
  import('../../../screens/MyAsset')
    .then(module => ({default: module.MyAsset})));

const MyAssetNavigation = [
  {
    path: ROUTE_MY_ASSEST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MyAsset />
      </Suspense>
    ),
  },
];

export default MyAssetNavigation;
