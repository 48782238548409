import PropTypes from 'prop-types';
import {Form, Select} from 'antd';
import {connect} from 'react-redux';
import {SystemSelectNames} from '../SystemSelectEntityEnum';
import {isArray} from 'lodash';
import {RQCommonListData} from '@app/reactQuery/apis';
import {selectAuth} from '@app/redux/selectors';
import {useState} from 'react';

const SingleSelectDependentObjectAttribute = ({auth, attributeMetadata, formValues}) => {
  const form = Form.useFormInstance();

  const [searchValue, setSearchValue] = useState('');

  const parentValue = formValues[attributeMetadata?.input_parent_id];
  const selectEnum = SystemSelectNames[attributeMetadata?.select_entity];

  const updatedMisc = {...selectEnum.misc};
  Object.keys(updatedMisc).forEach(key => {
    const value = updatedMisc[key];
    if (value.startsWith('#') && value.endsWith('#')) {
      updatedMisc[key] = parentValue;
    }
  });
  const {data} =
      RQCommonListData(
        {
          misc: updatedMisc,
          name: selectEnum?.name,
          api: selectEnum?.api,
          certificate: auth?.certificate,
          userId: auth?.principal?.id,
          locationId: auth?.user?.location?.id,
          filter: {
            ...selectEnum?.filter ||
              (attributeMetadata?.input_search_enable ? {[attributeMetadata.input_search_field_name]: searchValue} : {}),
          },
        }, {
          enabled: !!parentValue && (!attributeMetadata?.input_search_enable || searchValue.length > 2),
        });

  const dependentData = (attributeMetadata?.input_search_enable && searchValue.length < 3)
    ? []
    : isArray(data)
      ? data
      : data
        ? [data]
        : [];

  const isVertical = attributeMetadata?.layout === 'vertical';
  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      wrapperCol={{
        span: isVertical
          ? ((isVertical && attributeMetadata?.vertical_value_col_span)
            ? attributeMetadata?.vertical_value_col_span
            : 24)
          : 20,
      }}
      labelCol={{
        span: isVertical ? 24 : 4,
        ...((isVertical && attributeMetadata?.vertical_label_height)
          ? {style: {height: attributeMetadata?.vertical_label_height}}
          : {}),
      }}
      rules={attributeMetadata.validation_rules}
    >

      <Select
        allowClear={true}
        // mode={attributeMetadata.multiple === true ? 'multiple' : 'single'}
        filterOption={(input, option) =>
          option.children?.filter(c => c !== undefined && c.toLowerCase().indexOf(input?.toLowerCase()) >= 0)[0]
        }
        showSearch={attributeMetadata.show_search}
        placeholder={attributeMetadata?.placeholder}
        disabled={!attributeMetadata.editable}
        onChange={obj => {
          const fieldLabelValue = dependentData.filter(val => val?.id === obj)[0]?.name;
          if (form.getFieldValue(attributeMetadata.id + '_label') !== fieldLabelValue) {
            form.setFieldValue(attributeMetadata.id + '_label', fieldLabelValue);
          }
        }}
        onSearch={value => setSearchValue(value)}
        searchValue={searchValue}
      >
        {dependentData?.map((object, index) => {
          return (
            <Select.Option
              value={object?.id?.id ? object.id.id : object?.id ? object.id : object?.locationId}
              key={index}
            >
              {object?.name}
              {object?.locationName}
              {object?.locationType}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

SingleSelectDependentObjectAttribute.propTypes = {
  auth: PropTypes.shape({
    certificate: PropTypes.string.isRequired,
    principal: PropTypes.any,
    user: PropTypes.any,
  }).isRequired,
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    child_id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    select_entity: PropTypes.string.isRequired,
    input_options_dependent: PropTypes.array.isRequired,
    input_parent_id: PropTypes.string.isRequired,
    input_parent_value: PropTypes.string,
    input_search_enable: PropTypes.bool,
    input_search_field_name: PropTypes.string,
    show_search: PropTypes.bool,
    layout: PropTypes.string,
    vertical_value_col_span: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical_label_height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  formValues: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SingleSelectDependentObjectAttribute);
