import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';

const HiddenAttribute = ({attributeMetadata}) => {
  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.id}
      noStyle
    >
      <Input type={'hidden'} />
    </Form.Item>
  );
};

HiddenAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default HiddenAttribute;
