export const RQ_GET_MTP_REPORTS_API = {
  url:'reports/web/effort/mtp/<%= locationId %>/<%= fromYearMonthDate %>/<%= toYearMonthDate %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DATE_FILTER_API = {
  url:'mtpcyclemaster/list/<%= userId %>',
  method:'GET',
  auth:true,
};
