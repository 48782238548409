export const RQ_GET_PRODUCT_API = {
  url:'product/by-brand/<%= brandId%>',
  method:'GET',
  auth:true,
};

export const RQ_GET_PRODUCT_DATA_BY_BRAND_API = {
  url:'product/by-brand/<%= brandId  %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_PRODUCT_FIND_BY_ID = {
  url: 'product/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_CREATE_PRODUCT_API = {
  url: 'product/create',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_PRODUCT_ALL_FIELDS_API = {
  url: 'product/update',
  method: 'PUT',
  auth: true,
};
