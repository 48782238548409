import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_FIELD_STRUCTURE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const FieldStructure = lazy(() =>
  import('../../../screens/FieldStructure')
    .then(module => ({default: module.FieldStructure})));

const FieldStructureNavigation = [
  {
    path: ROUTE_FIELD_STRUCTURE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <FieldStructure />
      </Suspense>
    ),
  },
];

export default FieldStructureNavigation;
