export const RQ_GET_BEATS_BY_LOCATION_ID_API = {
  url:'beat/by-location/<%= locationId  %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_BEAT_BY_ID_API  = {
  url:'beat/fetch-beat/<%= beatId  %>',
  method:'GET',
  auth:true,
};

export const RQ_CREATE_BEAT_API  = {
  url:'beat/save-beat',
  method:'POST',
  auth:true,
};
