import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_UNLOCKING_HISTORY_TREAD_API} from '@app/reactQuery/apis/constants/rqUnlockingHistoryTreadAPIConstants';

export const RQUseGetUnlockingHistoryTreadData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_unlocking_history_tread_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDateStr, toDateStr} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_UNLOCKING_HISTORY_TREAD_API,
        null,
        certificate,
        {
          divisionId: divisionId,
          fromDateStr: fromDateStr,
          toDateStr: toDateStr,
        },
        signal,
      );
    },
    ...options,
  });
};
