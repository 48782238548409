import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_UNLOCK_EXPENCE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const UnlockExpence = lazy(() =>
  import('../../../screens/UnlockExpence')
    .then(module => ({default: module.UnlockExpence})));

const UnlockExpenceNavigation = [
  {
    path: ROUTE_UNLOCK_EXPENCE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UnlockExpence />
      </Suspense>
    ),
  },
];

export default UnlockExpenceNavigation;
