export const RQ_GET_LEAVE_APPROVAL_LIST_API = {
  url: 'leaves/approval-list/<%= managerId %>/<%= yearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_LEAVE_APPROVE_REJECT_API = {
  url: 'leaves/approval/<%= itemId %>/<%= managerId %>/<%= status %>',
  method: 'PUT',
  auth: true,
};
