import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_HOLIDAYS, ROUTE_EDIT_HOLIDAYS, ROUTE_HOLIDAYS} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Holidays = lazy(() =>
  import('../../../screens/Holidays')
    .then(module => ({default: module.Holidays})));

const AddHolidays = lazy(() =>
  import('../../../screens/Holidays')
    .then(module => ({default: module.AddHolidays})));

const EditHolidays = lazy(() =>
  import('../../../screens/Holidays')
    .then(module => ({default: module.EditHolidays})));

const HolidaysNavigation = [
  {
    path: ROUTE_HOLIDAYS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Holidays />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_HOLIDAYS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddHolidays />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_HOLIDAYS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditHolidays />
      </Suspense>
    ),
  },
];

export default HolidaysNavigation;
