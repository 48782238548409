import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_HOSPITAL_API,
  RQ_GET_HOSPITAL_FIND_BY_ID,
  RQ_GET_HOSPITAL_MASTER_UPDATION_LIST_API,
  RQ_PUT_HOSPITAL_DEACTIVATE_BY_ID,
  RQ_UPDATE_HOSPITAL_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetHospitalMasterUpdationData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_hospital_master_updation_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_HOSPITAL_MASTER_UPDATION_LIST_API,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};
export const RQPostCreateHospital = ({hospital, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_HOSPITAL_API,
    JSON.stringify({...hospital}),
    certificate,
    null,
    signal,
  );
};
export const RQPostUpdateHospital = ({hospital, certificate, id, signal}) => {
  return rqCreateRequest(
    RQ_UPDATE_HOSPITAL_API,
    JSON.stringify({...hospital, id:id}),
    certificate,
    null,
    signal,
  );
};
export const RQUsePutHospitalDeactivateById = async ({
  certificate,
  signal,
  hospitalId,
}) => {
  return rqCreateRequest(
    RQ_PUT_HOSPITAL_DEACTIVATE_BY_ID,
    null,
    certificate,
    {hospitalId: hospitalId},
    signal,
  );
};
export const RQUseGetHospitalFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_hospital_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_HOSPITAL_FIND_BY_ID,
        null,
        certificate,
        {hospitalId: id},
        signal,
      );
    },
    ...options,
  });
};
