import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_STATE, ROUTE_STATE, ROUTE_UPDATE_STATE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const State = lazy(() =>
  import('../../../screens/State')
    .then(module => ({default: module.States})));

const CreateState = lazy(() =>
  import('../../../screens/State')
    .then(module => ({default: module.CreateState})));

const UpdateState = lazy(() =>
  import('../../../screens/State')
    .then(module => ({default: module.UpdateState})));

const StateNavigation = [
  {
    path: ROUTE_STATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <State />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_STATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateState />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_STATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateState />
      </Suspense>
    ),
  },
];

export default StateNavigation;
