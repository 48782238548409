import CommonStatusEnum from '@app/dto/enums/CommonStatusEnum';
import {PRIMARY_900} from '@app/style/GlobalColors';

// Supported Tag colors:
// "blue", "purple", "cyan", "green", "magenta", "pink", "red",
// "orange", "yellow", "volcano", "geekblue", "lime", "gold"
export const getStatusColor = status => {
  switch (status) {
    case CommonStatusEnum.ACTIVE:
    case CommonStatusEnum.IN_PROGRESS:
      return 'cyan';
    case CommonStatusEnum.UNLOCKED:
      return 'lime';
    case CommonStatusEnum.SUBMITTED:
      return 'geekblue';
    case CommonStatusEnum.APPROVED:
      return 'green';
    case CommonStatusEnum.REJECTED:
    case CommonStatusEnum.DELETED:
    case CommonStatusEnum.DELETE:
    case CommonStatusEnum.INACTIVE:
      return 'red';
    case CommonStatusEnum.DRAFT:
      return 'gold';
    default:
      return PRIMARY_900;
  }
};
