import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_LEAVE_BALANCE_REPORTS_API} from '@app/reactQuery/apis/constants';

export const RQUseGetLeaveBalanceReportData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_leave_balance_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate,  year, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LEAVE_BALANCE_REPORTS_API,
        null,
        certificate,
        {locationId:locationId,
          year:year,
        },
        signal,
      );
    },
    ...options,
  });
};
