export const RQ_GET_HOLIDAYS_LIST_API = {
  url: 'holiday/list',
  method: 'GET',
  auth: true,
};

export const RQ_GET_HOLIDAY_FIND_BY_ID = {
  url: 'holiday/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_HOLIDAY_ALL_FIELDS_API = {
  url: 'holiday/update',
  method: 'PUT',
  auth: true,
};

export const RQ_CREATE_HOLIDAY_API = {
  url: 'holiday/create',
  method: 'POST',
  auth: true,
};
