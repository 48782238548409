export const RQ_GET_JOBROLE_LIST_API = {
  url: 'jobrole/getAll',
  method: 'GET',
  auth: true,
};

export const RQ_CREATE_JOBROLE_API = {
  url: 'jobrole/create',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_JOB_ROLE_FIND_BY_ID = {
  url: 'jobrole/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_JOB_ROLE_ALL_FIELDS_API = {
  url: 'jobrole/update',
  method: 'PUT',
  auth: true,
};
