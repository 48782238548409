import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_SPECIAL_FARE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const AddSpecialFare = lazy(() =>
  import('../../../screens/AddSpecialFare')
    .then(module => ({default: module.AddSpecialFare})));

const AddSpecialFareNavigation = [
  {
    path: ROUTE_ADD_SPECIAL_FARE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddSpecialFare />
      </Suspense>
    ),
  },
];

export default AddSpecialFareNavigation;
