import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_UPDATION_LINK} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const CustomerUpdationLink = lazy(() =>
  import('../../../screens/CustomerUpdationLink')
    .then(module => ({default: module.CustomerUpdationLink})));

const CustomerUpdationLinkNavigation = [
  {
    path: ROUTE_UPDATION_LINK,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerUpdationLink />
      </Suspense>
    ),
  },
];

export default CustomerUpdationLinkNavigation;
