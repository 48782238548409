export const RQ_GET_HOSPITAL_MASTER_UPDATION_LIST_API = {
  url: 'hospital/list/<%= locationId %>',
  method: 'GET',
  auth: true,
};
export const RQ_CREATE_HOSPITAL_API = {
  url: 'hospital/save-hospital',
  method: 'POST',
  auth: true,
};
export const RQ_UPDATE_HOSPITAL_API = {
  url: 'hospital/save-hospital',
  method: 'POST',
  auth: true,
};
export const RQ_GET_HOSPITAL_BY_TYPE_API = {
  url:'systemlov/by-type/HOSPITAL_CLASSIFICATION',
  method:'GET',
  auth:true,
};
export const RQ_PUT_HOSPITAL_DEACTIVATE_BY_ID = {
  url: 'hospital/deactivate/<%= hospitalId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_HOSPITAL_FIND_BY_ID = {
  url: 'hospital/restore/<%= hospitalId %>',
  method: 'GET',
  auth: true,
};
