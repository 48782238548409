import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_NON_REPORTED_USERS} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const NonReportedUsers = lazy(() =>
  import('../../../screens/NonReportedUsers')
    .then(module => ({default: module.NonReportedUsers})));

const NonReportedUsersNavigation = [
  {
    path: ROUTE_NON_REPORTED_USERS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <NonReportedUsers />
      </Suspense>
    ),
  },
];

export default NonReportedUsersNavigation;
