import {
  ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESSFUL,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESSFUL,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCESSFUL,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_FAIL,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_START,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESSFUL,
  LOGOUT_FAIL,
  LOGOUT_START,
  LOGOUT_SUCCESSFUL,
  UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESSFUL,
} from '../actionConstants';

export const loginStart = payload => async dispatch =>
  dispatch({
    type: LOGIN_START,
    payload: payload,
  });

export const loginSuccessful = payload => async dispatch =>
  dispatch({
    type: LOGIN_SUCCESSFUL,
    payload: payload,
  });

export const loginFail = payload => async dispatch =>
  dispatch({
    type: LOGIN_FAIL,
    payload: payload,
  });

export const logoutStart = payload => async dispatch => {
  dispatch({
    type: LOGOUT_START,
    payload: payload,
  });
};

export const logoutSuccessful = payload => async dispatch => {
  dispatch({
    type: LOGOUT_SUCCESSFUL,
    payload: payload,
  });
};

export const logoutFail = payload => async dispatch => {
  dispatch({
    type: LOGOUT_FAIL,
    payload: payload,
  });
};

export const getUserProfileStart = payload => async dispatch => {
  dispatch({
    type: GET_USER_PROFILE_START,
    payload: payload,
  });
};

export const getUserProfileSuccessful = payload => async dispatch => {
  dispatch({
    type: GET_USER_PROFILE_SUCCESSFUL,
    payload: payload,
  });
};

export const getUserProfileFail = payload => async dispatch => {
  dispatch({
    type: GET_USER_PROFILE_FAIL,
    payload: payload,
  });
};

export const identifyAuthExistOnDeviceStart = payload => dispatch => {
  dispatch({
    type: IDENTIFY_AUTH_EXIST_ON_DEVICE_START,
    payload: payload,
  });
};

export const identifyAuthExistOnDeviceSuccessful = payload => dispatch => {
  dispatch({
    type: IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
    payload: payload,
  });
};

export const identifyAuthExistOnDeviceFail = payload => dispatch => {
  dispatch({
    type: IDENTIFY_AUTH_EXIST_ON_DEVICE_FAIL,
    payload: payload,
  });
};

export const updateUserProfileStart = payload => async dispatch => {
  dispatch({
    type: UPDATE_USER_PROFILE_START,
    payload: payload,
  });
};

export const updateUserProfileSuccessful = payload => async dispatch => {
  dispatch({
    type: UPDATE_USER_PROFILE_SUCCESSFUL,
    payload: payload,
  });
};

export const updateUserProfileFail = payload => async dispatch => {
  dispatch({
    type: UPDATE_USER_PROFILE_FAIL,
    payload: payload,
  });
};

export const addUserProfileToLocalStorage = payload => async dispatch => {
  dispatch({
    type: ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE,
    payload: payload,
  });
};

export const forgotPasswordStart = payload => async dispatch =>
  dispatch({
    type: FORGOT_PASSWORD_START,
    payload: payload,
  });

export const forgotPasswordSuccessful = payload => async dispatch =>
  dispatch({
    type: FORGOT_PASSWORD_SUCCESSFUL,
    payload: payload,
  });

export const forgotPasswordFail = payload => async dispatch =>
  dispatch({
    type: FORGOT_PASSWORD_FAIL,
    payload: payload,
  });

export const changePasswordSuccessful = payload => async dispatch =>
  dispatch({
    type: CHANGE_PASSWORD_SUCCESSFUL,
    payload: payload,
  });

export const changePasswordFail = payload => async dispatch =>
  dispatch({
    type: CHANGE_PASSWORD_FAIL,
    payload: payload,
  });
