import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_DOCTOR_VISIT_SUMMARY} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const DoctorVisitSummary = lazy(() =>
  import('../../../screens/DoctorVisitSummary')
    .then(module => ({default: module.DoctorVisitSummary})));

const DoctorVisitSummaryNavigation = [
  {
    path: ROUTE_DOCTOR_VISIT_SUMMARY,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DoctorVisitSummary />
      </Suspense>
    ),
  },
];

export default DoctorVisitSummaryNavigation;
