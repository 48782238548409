export const RQ_POST_CUSTOMER_UPDATION_LINK_USER_API = {
  url: 'config/add-config/<%= type %>/<%= employeeCode %>/<%= ownerType %>',
  method: 'POST',
  auth: true,
};
export const RQ_GET_CUSTOMER_UPDATION_LINK_USER_API = {
  url: 'config/by-name/<%= configName %>',
  method: 'GET',
  auth: true,
};
export const RQ_PUT_DELETE_CUSTOMER_UPDATION_LINK_USER_API = {
  url: 'config/delete/<%= configId %>',
  method: 'PUT',
  auth: true,
};
