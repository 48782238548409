import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_INPUT_STOCK_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const InputStockReport = lazy(() =>
  import('../../../screens/InputStockReport')
    .then(module => ({default: module.InputStockReport})));

const InputStockReportNavigation = [
  {
    path: ROUTE_INPUT_STOCK_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <InputStockReport />
      </Suspense>
    ),
  },
];

export default InputStockReportNavigation;
