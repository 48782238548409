import React, {useState} from 'react';
import {Button, Modal} from 'antd';
import {FormBuilder} from '@app/components/common/FormBuilder';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useMutation} from '@tanstack/react-query';
import {RQUserChangePasswordAPI} from '@app/reactQuery/apis';
import {LoadingSpinner} from '@app/components/common/LoadingSpinner';
import {changePasswordFail, changePasswordSuccessful} from '@app/redux/actions';
import {KeyOutlined} from '@ant-design/icons';

const ChangePassword = ({auth, handleChangePasswordFail, handleChangePasswordSuccessful}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const mutationChangePassword = useMutation({
    mutationFn: payload => RQUserChangePasswordAPI(payload),
    onSuccess: payload => {
      setIsModalVisible(false);
      handleChangePasswordSuccessful();

    },
    onError: () => handleChangePasswordFail(),
  });

  const changePassword = password => {
    mutationChangePassword.mutate({
      certificate: auth?.certificate,
      password:{
        newPassword: password?.newPassword,
        oldPassword: password?.oldPassword,
        userName: password?.userName,
      },
    });
  };
  return (
    <>
      <Button className={'w-full'} type={'link'} onClick={showModal}>
        <KeyOutlined style={{lineHeight: 'inherit'}} />
               Change Password
      </Button>

      <Modal  width={800} open={isModalVisible}  onCancel={handleCancel} footer={null}>
        {(mutationChangePassword.isPending) && (<LoadingSpinner />)}
        <FormBuilder
          formMetadata={require('./ChangePasswordEntityMetadata.json')}
          onSubmitAction={changePassword}
        />
      </Modal>

    </>
  );
};

ChangePassword.propTypes = {
  auth: PropTypes.shape({
    certificate: PropTypes.string,
    user: PropTypes.string,
  }).isRequired,
  handleChangePasswordSuccessful: PropTypes.func.isRequired,
  handleChangePasswordFail: PropTypes.func.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

const actions = {
  handleChangePasswordSuccessful: changePasswordSuccessful,
  handleChangePasswordFail: changePasswordFail,
};

export default connect(mapState, actions)(ChangePassword);
