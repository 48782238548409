import React from 'react';
import PropTypes from 'prop-types';
import {Form, Select} from 'antd';
import {sortByDisplayOrder} from '../../../utils';

const SelectAttribute = ({attributeMetadata}) => {
  const isVertical = attributeMetadata?.layout === 'vertical';

  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      rules={attributeMetadata.validation_rules}
      wrapperCol={{
        span: isVertical
          ? ((isVertical && attributeMetadata?.vertical_value_col_span)
            ? attributeMetadata?.vertical_value_col_span
            : 24)
          : 20,
      }}
      labelCol={{
        span: isVertical ? 24 : 4,
        ...((isVertical && attributeMetadata?.vertical_label_height)
          ? {style: {height: attributeMetadata?.vertical_label_height}}
          : {}),
      }}
    >
      <Select
        placeholder={attributeMetadata?.placeholder}
        disabled={!attributeMetadata.editable}
      >
        {attributeMetadata?.input_options
          .sort(sortByDisplayOrder)
          .map((option, index) => (
            <Select.Option
              value={option.value}
              key={index}
            >
              {option.label}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

SelectAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    input_options: PropTypes.array.isRequired,
    layout: PropTypes.string,
    vertical_value_col_span: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical_label_height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default SelectAttribute;
