import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_HOSPITAL_RCPA_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const HospitalRCPAReport = lazy(() =>
  import('../../../screens/HospitalRCPAReport')
    .then(module => ({default: module.HospitalRCPAReport})));

const HospitalRCPAReportNavigation = [
  {
    path: ROUTE_HOSPITAL_RCPA_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <HospitalRCPAReport />
      </Suspense>
    ),
  },
];

export default HospitalRCPAReportNavigation;
