import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_MTP_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const MTPReport = lazy(() =>
  import('../../../screens/MTPReport')
    .then(module => ({default: module.MTPReport})));

const MTPReportNavigation = [
  {
    path: ROUTE_MTP_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MTPReport />
      </Suspense>
    ),
  },
];

export default MTPReportNavigation;
