import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_DOCTOR_API,
  RQ_GET_BRAND_BY_DOCTOR_ID_API,
  RQ_GET_BRAND_BY_LOCATION_ID_API,
  RQ_GET_CONFIG_BY_LOCATION_ID,
  RQ_GET_DIGITAL_PREFERENCE_BY_DOCTOR_ID_API,
  RQ_GET_DOCTOR_360_LAST_TEN_VISIT_API,
  RQ_GET_DOCTOR_360_LAST_TEN_VISIT_RCPA_API,
  RQ_GET_DOCTOR_360_VISIT_FREQUENCY,
  RQ_GET_DOCTOR_BY_CLASSIFICATION_LOCATION_ID,
  RQ_GET_DOCTOR_BY_LOCATION_ID,
  RQ_GET_DOCTOR_CHEMIST_API,
  RQ_GET_DOCTOR_DUMP_BY_DOCTOR_ID,
  RQ_GET_DOCTOR_FIND_BY_ID,
  RQ_GET_DOCTOR_FROM_UID_BY_LOCATION_ID,
  RQ_GET_DOCTOR_INFO_BY_DOCTOR_ID_API,
  RQ_GET_DOCTOR_POTENTIAL_BY_DOCTOR_ID_API,
  RQ_GET_DOCUMENT_BY_OWNER_ID_TYPE_ID_API,
  RQ_GET_FAMILY_DETAILS_BY_DOCTOR_ID_API,
  RQ_GET_FOOD_PREFERENCE_BY_DOCTOR_ID_API,
  RQ_GET_KOL_DETAILS_BY_DOCTOR_ID_API,
  RQ_GET_PRACTICING_PROFILE_BY_DOCTOR_ID_API,
  RQ_GET_SPORT_PREFERENCE_BY_DOCTOR_ID_API,
  RQ_POST_ADDRESS_SAVE_ALL_FIELDS_API,
  RQ_POST_ADD_DOCTOR_VIA_UID,
  RQ_POST_DOCTOR_POTENTIAL_SAVE_ALL_FIELDS_API,
  RQ_POST_UPLOAD_IMAGE,
  RQ_PUT_DOCTOR_DEACTIVATE_BY_DOCTOR_ID,
  RQ_PUT_DOCTOR_DELETE_BY_DOCTOR_ID,
  RQ_SAVE_DOCTOR_ADDITIONAL_INFO_API,
  RQ_SAVE_DOCTOR_BRAND_BY_DOCTOR_ID_API,
  RQ_SAVE_PRACTICING_DETAILS_ALL_FIELDS_API,
  RQ_UPDATE_CONTACT_INFO_ALL_FIELDS_API,
  RQ_UPDATE_DIGITAL_PREFERENCES_ALL_FIELDS_API,
  RQ_UPDATE_DIVISION_PROFILE_ALL_FIELDS_API,
  RQ_UPDATE_DOCTOR_KOL_PROFILE_ALL_FIELDS_API,
  RQ_UPDATE_FAMILY_DETAILS_ALL_FIELDS_API,
  RQ_UPDATE_FOOD_PREFERENCES_ALL_FIELDS_API,
  RQ_UPDATE_SPORTS_PREFERENCES_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetDoctorAllByLocationId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_all_by_location_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_BY_LOCATION_ID,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUsePutDoctorDeactivateByDoctorId = async ({
  certificate,
  signal,
  doctorId,
}) => {
  return rqCreateRequest(
    RQ_PUT_DOCTOR_DEACTIVATE_BY_DOCTOR_ID,
    null,
    certificate,
    {doctorId: doctorId},
    signal,
  );
};

export const RQUseGetDoctorByClassificationLocationId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_all_by_classification_location_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_BY_CLASSIFICATION_LOCATION_ID,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQPOSTCreateDoctor = ({doctor, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_DOCTOR_API,
    JSON.stringify({...doctor}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetDoctorFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutContactInfoUpdateAllField = async ({
  contact,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_CONTACT_INFO_ALL_FIELDS_API,
    JSON.stringify({...contact, id:id}),
    certificate,
    null,
    signal,
  );
};

export const RQPostDivisionProfileUpdateAllField = async ({
  doctor,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_DIVISION_PROFILE_ALL_FIELDS_API,
    JSON.stringify({...doctor, id:id}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetBrandData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_brand_by_location_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BRAND_BY_LOCATION_ID_API,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetBrandDataByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_brand_data_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BRAND_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId: doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQPostSaveDoctorBrandByDoctorId = async ({
  certificate,
  signal,
  doctorId,
  brandId,
  sequence,
}) => {
  return rqCreateRequest(
    RQ_SAVE_DOCTOR_BRAND_BY_DOCTOR_ID_API,
    null,
    certificate,
    {doctorId: doctorId, brandId:brandId, sequence:sequence},
    signal,
  );
};

export const RQPostSportPreferenceUpdateAllField = async ({
  data,
  certificate,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_SPORTS_PREFERENCES_ALL_FIELDS_API,
    JSON.stringify([...data]),
    certificate,
    null,
    signal,
  );
};

export const RQPostDigitalPreferenceUpdateAllField = async ({
  data,
  certificate,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_DIGITAL_PREFERENCES_ALL_FIELDS_API,
    JSON.stringify([...data]),
    certificate,
    null,
    signal,
  );
};

export const RQPostFoodPreferenceUpdateAllField = async ({
  food,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_FOOD_PREFERENCES_ALL_FIELDS_API,
    JSON.stringify({...food}),
    certificate,
    null,
    signal,
  );
};

export const RQPostFamilyDetailsUpdateAllField = async ({
  family,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_FAMILY_DETAILS_ALL_FIELDS_API,
    JSON.stringify({...family}),
    certificate,
    null,
    signal,
  );
};

export const RQPutDoctorKolProfileUpdateAllField = async ({
  kolprofile,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_DOCTOR_KOL_PROFILE_ALL_FIELDS_API,
    JSON.stringify({...kolprofile}),
    certificate,
    null,
    signal,
  );
};

export const RQPostDoctorPotentialSaveAllField = async ({
  potential,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_POST_DOCTOR_POTENTIAL_SAVE_ALL_FIELDS_API,
    JSON.stringify({...potential}),
    certificate,
    null,
    signal,
  );
};

export const RQPostAddressSaveAllField = async ({
  address,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_POST_ADDRESS_SAVE_ALL_FIELDS_API,
    JSON.stringify({...address}),
    certificate,
    null,
    signal,
  );
};

export const RQPostSaveDoctorAdditionalInfoAllField = async ({
  info,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_SAVE_DOCTOR_ADDITIONAL_INFO_API,
    JSON.stringify({...info}),
    certificate,
    null,
    signal,
  );
};

export const RQPostPracticingDetailsSaveAllField = async ({
  details,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_SAVE_PRACTICING_DETAILS_ALL_FIELDS_API,
    JSON.stringify({...details}),
    certificate,
    null,
    signal,
  );
};

export const RQPostImageUpload = async ({
  image,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_POST_UPLOAD_IMAGE,
    JSON.stringify({...image}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetPracticingProfileByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_practicing_profile_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_PRACTICING_PROFILE_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId :doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetFoodPreferenceByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_food_preference_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_FOOD_PREFERENCE_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId :doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDigitalPreferenceByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_digital_preference_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DIGITAL_PREFERENCE_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId :doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetSportPreferenceByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_sport_preference_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SPORT_PREFERENCE_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId :doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDoctorInfoByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_info_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_INFO_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId :doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetFamilyDetailsByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_family_details_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_FAMILY_DETAILS_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId :doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetKolDetailsByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_kol_details_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_KOL_DETAILS_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId :doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDoctorPotentialByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_potential_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_POTENTIAL_BY_DOCTOR_ID_API,
        null,
        certificate,
        {doctorId :doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDocumentByOwnerIdAndTypeIdDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_document_by_owner_id_by_type_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, ownerId, typeId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCUMENT_BY_OWNER_ID_TYPE_ID_API,
        null,
        certificate,
        {ownerId :ownerId, typeId :typeId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDoctorFromUIDByLocationId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_from_uid_by_location_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_FROM_UID_BY_LOCATION_ID,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUsePostDoctorViaUID = async ({
  certificate,
  signal,
  doctorId,
  locationId,
}) => {
  return rqCreateRequest(
    RQ_POST_ADD_DOCTOR_VIA_UID,
    null,
    certificate,
    {doctorId: doctorId,  locationId :locationId},
    signal,
  );
};

export const RQUseGetConfigByLocationId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_config_by_location_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CONFIG_BY_LOCATION_ID,
        null,
        certificate,
        {locationId:locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUsePutBrandDeleteByDoctorId = async ({
  certificate,
  signal,
  doctorId,
  brandId,
}) => {
  return rqCreateRequest(
    RQ_PUT_DOCTOR_DELETE_BY_DOCTOR_ID,
    null,
    certificate,
    {doctorId: doctorId, brandId: brandId},
    signal,
  );
};

export const RQUseGetDoctorDumpByDoctorId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_dump_by_doctor_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_DUMP_BY_DOCTOR_ID,
        null,
        certificate,
        {doctorId:doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDoctor360LastTenVisits = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_360_last_ten_visit', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_360_LAST_TEN_VISIT_API,
        null,
        certificate,
        {locationId: locationId, doctorId:doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDoctor360LastTenVisitsRcpa = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_360_last_ten_visit_rcpa', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_360_LAST_TEN_VISIT_RCPA_API,
        null,
        certificate,
        {doctorId:doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDoctor360DoctorChemist = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_360_doctor_chemist', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_CHEMIST_API,
        null,
        certificate,
        {doctorId:doctorId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDoctor360VisitFrequency = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_360_visit_frequency', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, doctorId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_360_VISIT_FREQUENCY,
        null,
        certificate,
        {doctorId:doctorId},
        signal,
      );
    },
    ...options,
  });
};
