import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';

const EmailAttribute = ({attributeMetadata}) => {
  const isVertical = attributeMetadata?.layout === 'vertical';

  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      rules={attributeMetadata.validation_rules}
      wrapperCol={{
        span: isVertical
          ? ((isVertical && attributeMetadata?.vertical_value_col_span)
            ? attributeMetadata?.vertical_value_col_span
            : 24)
          : 20,
      }}
      labelCol={{
        span: isVertical ? 24 : 4,
        ...((isVertical && attributeMetadata?.vertical_label_height)
          ? {style: {height: attributeMetadata?.vertical_label_height}}
          : {}),
      }}
    >
      <Input
        placeholder={attributeMetadata?.placeholder}
        disabled={!attributeMetadata.editable}
        type={'email'}
      />
    </Form.Item>
  );
};

EmailAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    layout: PropTypes.string,
    placeholder: PropTypes.string,
    vertical_value_col_span: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical_label_height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default EmailAttribute;
