import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_RCPA_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const RCPAReport = lazy(() =>
  import('../../../screens/RCPAReport')
    .then(module => ({default: module.RCPAReport})));

const RCPAReportNavigation = [
  {
    path: ROUTE_RCPA_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <RCPAReport />
      </Suspense>
    ),
  },
];

export default RCPAReportNavigation;
