import {
  RQ_GET_CUSTOMER_UPDATION_LINK_USER_API,
  RQ_POST_CUSTOMER_UPDATION_LINK_USER_API,
  RQ_PUT_DELETE_CUSTOMER_UPDATION_LINK_USER_API,
} from '@app/reactQuery/apis/constants';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';

export const RQUsePostCustomerUpdationLinkData = async ({employeeCode, ownerType, type,
  customer,
  certificate,
  signal,
}) => {
  return rqCreateRequest(
    RQ_POST_CUSTOMER_UPDATION_LINK_USER_API,
    {customer},
    certificate,
    {employeeCode:employeeCode,
      ownerType:ownerType,
      type:type},
    signal,
  );
};
export const RQPutDeleteCustomerUpdation = ({configId, certificate, signal}) => {
  return rqCreateRequest(
    RQ_PUT_DELETE_CUSTOMER_UPDATION_LINK_USER_API,
    JSON.stringify({...configId}),
    certificate,
    {configId:configId},
    signal,
  );
};
export const RQUseGetCustomerUpdationData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_customer_updation_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, configName} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CUSTOMER_UPDATION_LINK_USER_API,
        null,
        certificate,
        {configName: configName},
        signal,
      );
    },
    ...options,
  });
};
