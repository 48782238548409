import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_CHEMIST_MASTER_UPDATION,
  ROUTE_CHEMIST_MASTER_UPDATION,
  ROUTE_EDIT_CHEMIST_MASTER_UPDATION,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const ChemistMasterUpdation = lazy(() =>
  import('../../../screens/ChemistMasterUpdation')
    .then(module => ({default: module.ChemistMasterUpdation})));

const CreateChemistMasterUpdation = lazy(() =>
  import('../../../screens/ChemistMasterUpdation')
    .then(module => ({default: module.CreateChemistMasterUpdation})));

const UpdateChemist = lazy(() =>
  import('../../../screens/ChemistMasterUpdation')
    .then(module => ({default: module.UpdateChemist})));

const ChemistMasterUpdationNavigation = [
  {
    path: ROUTE_CHEMIST_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ChemistMasterUpdation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_CHEMIST_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateChemistMasterUpdation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_CHEMIST_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateChemist />
      </Suspense>
    ),
  },
];

export default ChemistMasterUpdationNavigation;
