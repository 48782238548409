/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  corePlugins: {
    preflight: false,
  },
  theme: {
    extend: {},
    colors: {
      primary: 'var(--PRIMARY_COLOR)',
      'primary-50': 'var(--PRIMARY_50)',
      'primary-100': 'var(--PRIMARY_100)',
      'primary-200': 'var(--PRIMARY_200)',
      'primary-300': 'var(--PRIMARY_300)',
      'primary-400': 'var(--PRIMARY_400)',
      'primary-500': 'var(--PRIMARY_500)',
      secondary: 'var(--SECONDARY_COLOR)',
      'secondary-50': 'var(--SECONDARY_50)',
      'secondary-100': 'var(--SECONDARY_100)',
      'secondary-200': 'var(--SECONDARY_200)',
      'secondary-300': 'var(--SECONDARY_300)',
      'secondary-400': 'var(--SECONDARY_400)',
      gray: 'var(--GRAY_COLOR)',
      'gray-50': 'var(--GRAY_50)',
      'gray-100': 'var(--GRAY_100)',
      'gray-200': 'var(--GRAY_200)',
      'gray-300': 'var(--GRAY_300)',
      'gray-400': 'var(--GRAY_400)',
      'gray-500': 'var(--GRAY_500)',
      green: 'var(--GREEN_COLOR)',
      'green-50': 'var(--GREEN_50)',
      'green-200': 'var(--GREEN_200)',
      'green-300': 'var(--GREEN_300)',
      'green-400': 'var(--GREEN_400)',
      'green-500': 'var(--GREEN_500)',
      blue: 'var(--BLUE_COLOR)',
      'blue-50': 'var(--BLUE_50)',
      'blue-200': 'var(--BLUE_200)',
      'blue-300': 'var(--BLUE_300)',
      'blue-400': 'var(--BLUE_400)',
      'blue-500': 'var(--BLUE_500)',
      'blue-600': 'var(--BLUE_600)',
      'blue-700': 'var(--BLUE_700)',
      'blue-800': 'var(--BLUE_800)',
      'blue-900': 'var(--BLUE_900)',
      red: 'var(--RED_COLOR)',
      'red-50': 'var(--RED_50)',
      'red-200': 'var(--RED_200)',
      'red-300': 'var(--RED_300)',
      'red-400': 'var(--RED_400)',
      'red-500': 'var(--RED_500)',
      'red-600': 'var(--RED_600)',
      'red-700': 'var(--RED_700)',
      'red-800': 'var(--RED_800)',
      'red-900': 'var(--RED_900)',
      white: 'var(--WHITE_COLOR)',
      black: 'var(--BLACK_COLOR)',
    },
  },
  plugins: [],
  darkMode: 'media',
  variants: {
    extend: {},
  },
};
