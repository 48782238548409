import React from 'react';
import PropTypes from 'prop-types';
import {Form, InputNumber} from 'antd';

const NumberAttribute = ({attributeMetadata}) => {
  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      rules={attributeMetadata.validation_rules}
    >
      <InputNumber
        min={attributeMetadata?.minValue}
        placeholder={attributeMetadata?.placeholder}
        disabled={!attributeMetadata.editable}
        type={'number'}
        style={{width: attributeMetadata.width_in_percentage + '%'}}
      />
    </Form.Item>
  );
};

NumberAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    minValue: PropTypes.number,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    width_in_percentage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
};

export default NumberAttribute;
