import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_HOSPITAL_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const HospitalReport = lazy(() =>
  import('../../../screens/HospitalReport')
    .then(module => ({default: module.HospitalReport})));

const HospitalReportNavigation = [
  {
    path: ROUTE_HOSPITAL_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <HospitalReport />
      </Suspense>
    ),
  },
];

export default HospitalReportNavigation;
