import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_CML_REPORTS} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const CMLReport = lazy(() =>
  import('../../../screens/CMLReport')
    .then(module => ({default: module.CMLReport})));

const CMLReportNavigation = [
  {
    path: ROUTE_CML_REPORTS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CMLReport />
      </Suspense>
    ),
  },
];

export default CMLReportNavigation;
