export const RQ_USER_LOGIN_API = {
  url: 'auth/login',
  method: 'POST',
  auth: false,
};

export const RQ_USER_FORGOT_PASSWORD_API = {
  url: 'api/v1/user/forgot/password',
  method: 'PUT',
  auth: false,
};

export const RQ_USER_CHANGE_PASSWORD_API = {
  url: 'auth/change-password',
  method: 'POST',
  auth: true,
};
