import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_JOBROLE_API,
  RQ_GET_JOBROLE_LIST_API,
  RQ_GET_JOB_ROLE_FIND_BY_ID,
  RQ_UPDATE_JOB_ROLE_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetJobRoleListData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_jobrole_list_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_JOBROLE_LIST_API,
        null,
        certificate,
        null,
        signal,
      );
    },
    ...options,
  });
};

export const RQPutCreateJobRole = ({jobrole, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_JOBROLE_API,
    JSON.stringify({...jobrole}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetJobRoleFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_jobrole_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_JOB_ROLE_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutJobRoleUpdateAllField = async ({
  jobrole,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_JOB_ROLE_ALL_FIELDS_API,
    JSON.stringify({...jobrole, id:id}),
    certificate,
    null,
    signal,
  );
};
