import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_DOCTOR_APPROVAL_LIST_API,
  RQ_PUT_DOCTOR_APPROVAL_BY_DOCTOR_ID,
  RQ_PUT_DOCTOR_REJECT_BY_DOCTOR_ID,
} from '@app/reactQuery/apis/constants';

export const RQUseGetDoctorApprovalListData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_doctor_approval_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, employeeId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_APPROVAL_LIST_API,
        null,
        certificate,
        {divisionId:divisionId,
          employeeId:employeeId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUsePutDoctorApprovalByDoctorId = async ({
  certificate,
  signal,
  doctorId,
  managerId,
}) => {
  return rqCreateRequest(
    RQ_PUT_DOCTOR_APPROVAL_BY_DOCTOR_ID,
    null,
    certificate,
    {doctorId: doctorId, managerId: managerId},
    signal,
  );
};

export const RQUsePutDoctorRejectByDoctorId = async ({
  certificate,
  signal,
  doctorId,
  managerId,
}) => {
  return rqCreateRequest(
    RQ_PUT_DOCTOR_REJECT_BY_DOCTOR_ID,
    null,
    certificate,
    {doctorId: doctorId, managerId: managerId},
    signal,
  );
};
