//Auth APIs
export const USER_LOGOUT_API = {
  url: 'api/v1/user/<%= userId %>/logout',
  method: 'PUT',
  auth: true,
};

export const USER_PROFILE_API = {
  url: 'employee/profile/<%= userId %>',
  method: 'GET',
  auth: true,
};

export const USER_PROFILE_UPDATE_API = {
  url: 'api/v1/user/<%= userId %>/update/profile',
  method: 'PUT',
  auth: true,
};
