import React from 'react';
import PropTypes from 'prop-types';
import {Form, Radio} from 'antd';

const RadioButtonAttribute = ({attributeMetadata}) => {

  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      rules={attributeMetadata.validation_rules}
    >
      <Radio.Group
        allowClear={true}
        placeholder={attributeMetadata?.placeholder}
        showSearch={attributeMetadata?.show_search}
        disabled={!attributeMetadata.editable}
      >
        {attributeMetadata?.radio_options?.map((option, index) => (
          <Radio key={index} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

RadioButtonAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    radio_options: PropTypes.array.isRequired,
    show_search: PropTypes.bool,
  }).isRequired,
};

export default RadioButtonAttribute;
