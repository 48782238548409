import {Button, Col, Divider, Form, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import {buildFormEntityMetadata, buildFormInitialValues, buildFormSubmitValues, sortByDisplayOrder} from './utils';
import {assign, chunk, debounce} from 'lodash';
import StandardAttributeCreator from './StandardAttributeCreator';
import {STANDARD, SYSTEM} from './attributeTypes/AttributeCategoryEnum';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import SystemAttributeCreator from './SystemAttributeCreator';

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

const FormBuilder = ({
  formMetadata,
  onSubmitAction = formValues => false,
  onFormUpdateCallback = formValues => false,
  onFormUpdateGetStatusCallback = isValidStatus => false,
  disableFormSubmitButton = false,
  initialValues = {},
}) => {
  const {entityMetadata, formLayout} = formMetadata;
  const formEntityMetadata = buildFormEntityMetadata(entityMetadata);
  const formInitialValues = buildFormInitialValues(entityMetadata, initialValues);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [form] = Form.useForm();
  const debouncedSubmitAction = debounce(onSubmitAction, 500); // 500 ms debounce delay

  useLayoutEffect(() => {
    if (formEntityMetadata.capture_form_values_callback) {
      onFormUpdateCallback(buildFormInitialValues(entityMetadata, initialValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formEntityMetadata?.update_initial_values) {
      const updatedInitialValues = buildFormInitialValues(entityMetadata, initialValues);
      setFormValues(updatedInitialValues);
      form.setFieldsValue(updatedInitialValues);
    }
  }, [entityMetadata, form, formEntityMetadata?.update_initial_values, initialValues]);

  const updateDynamicFieldValue = (id, value) => {
    const updatedAllValues = assign({}, formValues, {[id]: value});
    setFormValues(updatedAllValues);
    if (formEntityMetadata.capture_form_values_callback) {
      onFormUpdateCallback(buildFormSubmitValues(entityMetadata, updatedAllValues));
    }
  };
  return (
    <Row>
      <Col span={24}>
        {
          formMetadata?.show_title && (
            <Row justify={'center'}>
              <Typography.Title level={3}>{formMetadata?.title}</Typography.Title>
            </Row>
          )
        }
        <Form
          {...formLayout}
          form={form}
          name={formMetadata?.id}
          onFinish={values => {
            (debouncedSubmitAction(buildFormSubmitValues(entityMetadata, values)), 1000);
          }}
          validateMessages={validateMessages}
          initialValues={{...formInitialValues}}
          size={'large'}
          validateTrigger={['onBlur', 'onFocus', 'onInput', 'onChange']}
          onValuesChange={(changedValues, allValues) => {
            setFormValues(allValues);
            if (formEntityMetadata.capture_form_values_callback) {
              onFormUpdateCallback(buildFormSubmitValues(entityMetadata, {...allValues}));
            }
            form?.validateFields(allValues)
              .then(values => {
                onFormUpdateGetStatusCallback(true);
              })
              .catch(errorInfo => {
                onFormUpdateGetStatusCallback(errorInfo?.errorFields?.length === 0);
              });
          }}
        >
          {formEntityMetadata?.sections
            .sort(sortByDisplayOrder)
            .map(section => (
              <div key={section.id}>
                {
                  section?.show_title && (
                    <Row justify={section?.title_alignment || 'center'}>
                      <Typography.Title
                        level={section?.title_size || 3}>{section.title}</Typography.Title>
                    </Row>
                  )
                }
                {
                  // depending on number of columns, render form
                  chunk(
                    section.attributes.sort(sortByDisplayOrder),
                    section.columns,
                  ).map((attributeArray, index) => {
                    return (
                      <Row key={index}>
                        {
                          attributeArray.map((attribute, index) => (
                            <Col key={index} span={(24 / section.columns)}>
                              {
                                attribute?.attribute_category === STANDARD
                                  ? (
                                    <StandardAttributeCreator
                                      key={attribute.id}
                                      attributeMetadata={attribute}
                                      formValues={formValues}
                                      updateDynamicFieldValue={updateDynamicFieldValue}
                                    />
                                  ) :
                                  attribute?.attribute_category === SYSTEM
                                    ? (
                                      <SystemAttributeCreator
                                        key={attribute.id}
                                        attributeMetadata={attribute}
                                        formValues={formValues}
                                      />
                                    ) : null
                              }
                            </Col>
                          ))
                        }
                      </Row>
                    );
                  })
                }
                {!section?.hide_divider && <Divider />}
              </div>
            ))
          }
          {
            !formEntityMetadata?.hide_submit_button && (
              <Row justify={'center'}>
                <Form.Item>
                  <Button type={'primary'} htmlType={'submit'} disabled={disableFormSubmitButton}>
                    {formEntityMetadata?.submit_button_text}
                  </Button>
                </Form.Item>
              </Row>
            )
          }
        </Form>
      </Col>
    </Row>
  );
};

FormBuilder.propTypes = {
  formMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    show_title: PropTypes.bool,
    entityMetadata: PropTypes.shape({
      submit_button_text: PropTypes.string,
      hide_submit_button: PropTypes.bool,
      capture_form_values_callback: PropTypes.bool,
      update_initial_values: PropTypes.bool,
    }).isRequired,
    formLayout: PropTypes.shape({
      wrapperCol: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  onSubmitAction: PropTypes.func,
  onFormUpdateCallback: PropTypes.func,
  onFormUpdateGetStatusCallback: PropTypes.func,
  initialValues: PropTypes.object,
  disableFormSubmitButton: PropTypes.bool,
};

export default FormBuilder;
