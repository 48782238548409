import React, {useState} from 'react';
import {Button, Modal} from 'antd';
import {FormBuilder} from '@app/components/common/FormBuilder';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {UserOutlined} from '@ant-design/icons';

const MyProfile = ({auth}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const InitialValues = {
    name: auth?.user?.employee?.name,
    empCode: auth?.user?.empCode,
    empDesignation: auth?.user?.empDesignation,
    dateOfJoining: auth?.user?.dateOfJoining,
    division:auth?.user?.division?.name,
    location: auth?.user?.location?.name,
  };
  return (
    <>
      <Button  className={'w-full'} type={'link'} onClick={showModal}>
        <UserOutlined  />
                   My Profile
      </Button>
      {auth?.user?.jobTitle?.id === 'jobrl10000000000000000000000000000001' ? (
        <Modal title={'MY Profile'} width={800} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
          <FormBuilder
            formMetadata={require('./AdminProfileEntityMetadata.json')}
            initialValues={InitialValues}
          />
        </Modal>

      ) : (
        <Modal title={'MY Profile'} width={800} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
          <FormBuilder
            formMetadata={require('./MyProfileEntityMetadata.json')}
            initialValues={InitialValues}
          />
        </Modal>
      )}
    </>
  );
};

MyProfile.propTypes = {
  auth: PropTypes.shape({
    certificate: PropTypes.string,
    user: PropTypes.object,
  }).isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(MyProfile);
