import {isEmpty, merge} from 'lodash';
import {
  CHECKBOX,
  DATE,
  FILE, MULTI_SELECT_DEPENDENT_OBJECT_ATTRIBUTE, MULTI_SELECT_OBJECT_ATTRIBUTE,
  NUMBER, SELECT, SELECT_OBJECT_ATTRIBUTE, SINGLE_SELECT_DEPENDENT_OBJECT_ATTRIBUTE,
} from '../attributeTypes/AttributeTypeEnum';
import dayjs from 'dayjs';
import {STANDARD, SYSTEM} from '../attributeTypes/AttributeCategoryEnum';

export const sortByDisplayOrder = (a, b) => a.display_order - b.display_order;

export const buildFormEntityMetadata = entityMetadata => {
  const standardEntityAttributeMetadata = require('../entityDefinition/StandardEntityAttributeMetadata.json');
  const systemEntityAttributeMetadata = require('../entityDefinition/SystemEntityAttributeMetadata.json');

  entityMetadata.sections = entityMetadata.sections.map(
    section => {
      section.attributes = section.attributes.map(sectionAttr => {
        const finalAttrMetadata =
                    sectionAttr?.attribute_category === STANDARD
                      ? standardEntityAttributeMetadata.find(stdAttr => stdAttr.id === sectionAttr.id)
                      : sectionAttr?.attribute_category === SYSTEM ?
                        systemEntityAttributeMetadata.find(systemAttr => systemAttr.id === sectionAttr.id)
                        : standardEntityAttributeMetadata.find(stdAttr => stdAttr.id === sectionAttr.id);

        if (sectionAttr.new_id) {
          sectionAttr.id = sectionAttr.new_id;
        }

        return merge({}, finalAttrMetadata, sectionAttr);
      });
      return section;
    },
  );

  return entityMetadata;
};

export const buildFormInitialValues = (
  entityMetadata,
  initialValues = {},
) => {
  const initialValuesData = {};
  entityMetadata?.sections.forEach(section => {
    section.attributes.forEach(
      attribute => {
        if (attribute?.input_type === DATE) {
          initialValuesData[attribute.id] = initialValues[attribute.id] ? dayjs(initialValues[attribute.id]) : undefined;
        } else if (attribute?.input_type === NUMBER) {
          initialValuesData[attribute.id] =
                !isEmpty(initialValues[attribute.id] + '')
                  ? initialValues[attribute.id]
                  : !isEmpty(attribute?.initial_value + '')
                    ? attribute?.initial_value
                    : '';
        } else if (attribute?.input_type === SELECT_OBJECT_ATTRIBUTE ||
            attribute?.input_type === SINGLE_SELECT_DEPENDENT_OBJECT_ATTRIBUTE) {
          if (initialValues[attribute.id]) {
            const attrValue = initialValues[attribute.id][attribute.child_id] || undefined;
            initialValuesData[attribute.id] = {[attribute.child_id]: attrValue};
            if (initialValues[attribute.id]['name']) {
              initialValuesData[attribute.id] = {
                ...initialValuesData[attribute.id],
                'label': initialValues[attribute.id]['name'],
              };
            }
          }
        } else if (attribute?.input_type === MULTI_SELECT_OBJECT_ATTRIBUTE ||
            attribute?.input_type === MULTI_SELECT_DEPENDENT_OBJECT_ATTRIBUTE) {
          if (initialValues[attribute.id]) {
            initialValuesData[attribute.id] = initialValues[attribute.id].map(attrValue => {
              if (attrValue['name']) {
                attrValue = {
                  ...attrValue,
                  'label': attrValue['name'],
                };
              }
              // return ({[attribute.child_id]: attrValue});
              return attrValue.id;
            });
          }
        } else if (attribute?.input_type === CHECKBOX) {
          initialValuesData[attribute.id] = initialValues[attribute.id] || attribute?.initial_value;
        } else if (attribute?.input_type === SELECT) {
          initialValuesData[attribute.id] =
              !isEmpty('' + initialValues[attribute.id])
                ? initialValues[attribute.id]
                : !isEmpty('' + attribute?.initial_value)
                  ? attribute?.initial_value
                  : '';
        } else if (attribute?.input_type === FILE) {
          initialValuesData[attribute.id] = initialValues[attribute.id] || [];
        }  else {
          initialValuesData[attribute.id] =
              !isEmpty(initialValues[attribute.id])
                ? initialValues[attribute.id]
                : !isEmpty(attribute?.initial_value)
                  ? attribute?.initial_value
                  : '';
        }
      },
    );
  });

  return initialValuesData;
};

export const buildFormSubmitValues = (entityMetadata, submitValues = {}) => {
  entityMetadata?.sections.forEach(section => {
    section.attributes.forEach(attribute => {
      if (attribute?.input_type === DATE) {
        submitValues[attribute.id] = dayjs(submitValues[attribute.id]).format('DD-MM-YYYY');
      } else if (attribute?.input_type === SELECT_OBJECT_ATTRIBUTE) {
        if (submitValues[attribute.id] && submitValues[attribute.id][attribute.child_id]) {
          submitValues[attribute.id] = {[attribute.child_id]: submitValues[attribute.id][attribute.child_id]};
        } else {
          submitValues[attribute.id] = {[attribute.child_id]: submitValues[attribute.id]};
        }
      } else if (attribute?.input_type === SINGLE_SELECT_DEPENDENT_OBJECT_ATTRIBUTE) {
        if (submitValues[attribute.id] && submitValues[attribute.id][attribute.child_id]) {
          submitValues[attribute.id] = {[attribute.child_id]: submitValues[attribute.id][attribute.child_id]};
        } else {
          submitValues[attribute.id] = {[attribute.child_id]: submitValues[attribute.id]};
        }
      } else if (attribute?.input_type === MULTI_SELECT_OBJECT_ATTRIBUTE) {
        if (submitValues[attribute.id] && submitValues[attribute.id][attribute.child_id]) {
          submitValues[attribute.id] = {[attribute.child_id]: submitValues[attribute.id][attribute.child_id]};
        } else {
          submitValues[attribute.id] = {[attribute.child_id]: submitValues[attribute.id]};
        }
      } else if (attribute?.input_type === MULTI_SELECT_DEPENDENT_OBJECT_ATTRIBUTE) {
        if (submitValues[attribute.id] && submitValues[attribute.id][attribute.child_id]) {
          submitValues[attribute.id] = {[attribute.child_id]: submitValues[attribute.id][attribute.child_id]};
        } else {
          submitValues[attribute.id] = {[attribute.child_id]: submitValues[attribute.id]};
        }
      }
    });
  });
  return submitValues;
};
