import React from 'react';
import {RootNavigator} from '@app/navigations';
import {App as AntdApp, ConfigProvider} from 'antd';
import {PrimaryTheme} from '@app/style';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import configureStore from '@app/redux/store/configureStore';
import {Provider} from 'react-redux';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import GlobalComponent from '@app/components/global/GlobalComponent';
import MainProvider from '@app/navigations/provider/MainProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const store = configureStore();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store = {store}>
        <ConfigProvider theme={PrimaryTheme}>
          <MainProvider>
            <AntdApp>
              <GlobalComponent />
              <RootNavigator />
              <ReactQueryDevtools />
            </AntdApp>
          </MainProvider>
        </ConfigProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
