import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_BRAND_API,
  RQ_GET_BRAND_DATA_BY_DIVISION_API,
  RQ_GET_BRAND_FIND_BY_ID,
  RQ_GET_BRAND_LIST_API,
  RQ_UPDATE_BRAND_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants/rqBrandAPIConstants';

export const RQUseGetBrandListData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_brand_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BRAND_LIST_API,
        null,
        certificate,
        null,
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetBrandDataByDivisionId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_brand_data_by_division_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BRAND_DATA_BY_DIVISION_API,
        null,
        certificate,
        {divisionId: divisionId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetBrandFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_brand_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BRAND_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutCreateBrand = ({brand, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_BRAND_API,
    JSON.stringify({...brand}),
    certificate,
    null,
    signal,
  );
};

export const RQPutBrandUpdateAllField = async ({
  brand,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_BRAND_ALL_FIELDS_API,
    JSON.stringify({...brand, id:id}),
    certificate,
    null,
    signal,
  );
};
