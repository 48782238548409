import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_EMPLOYEE_MY_TEAM_LOCATION_BY_LOCATIONID_API,
  RQ_GET_EMPLOYE_LOCATION_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetEmployeLocationData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_employe_location_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_EMPLOYE_LOCATION_API,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetEmployeeMyTeamLocationDataByLocationId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_employee_my_team_location_data_by_locationId', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_EMPLOYEE_MY_TEAM_LOCATION_BY_LOCATIONID_API,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};
