import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_LEAVE_APPROVAL_LIST_API,
  RQ_LEAVE_APPROVE_REJECT_API,
} from '@app/reactQuery/apis/constants/rqLeaveApprovalAPIConstants';

export const RQUseGetLeaveApprovalListData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_leave_approval_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, managerId, yearMonth} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LEAVE_APPROVAL_LIST_API,
        null,
        certificate,
        {managerId:managerId, yearMonth:yearMonth},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseLeaveApproveRejectRequest = async ({certificate, signal, itemId, managerId, status}) => {
  return rqCreateRequest(
    RQ_LEAVE_APPROVE_REJECT_API,
    null,
    certificate,
    {itemId: itemId, managerId:managerId, status: status},
    signal,
  );
};
