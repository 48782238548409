import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_MY_ASSET_API} from '@app/reactQuery/apis/constants';

export const RQUseGetMyAssetData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_my_asset_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, employeeId, status} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_MY_ASSET_API,
        null,
        certificate,
        {employeeId:employeeId,
          status:status},
        signal,
      );
    },
    ...options,
  });
};
