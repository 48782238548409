import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_YOUTUBE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const YouTube = lazy(() =>
  import('../../../screens/YouTube')
    .then(module => ({default: module.YouTube})));

const YouTubeNavigation = [
  {
    path: ROUTE_ADD_YOUTUBE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <YouTube />
      </Suspense>
    ),
  },
];

export default YouTubeNavigation;
