import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_CHEMIST_APPROVAL_LIST_API, RQ_GET_CHEMIST_FIND_BY_CHEMIST_ID,
  RQ_PUT_CHEMIST_APPROVAL_BY_CHEMIST_ID,
  RQ_PUT_CHEMIST_REJECT_BY_CHEMIST_ID,
} from '@app/reactQuery/apis/constants';

export const RQUseGetChemistApprovalListData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_chemist_approval_list_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, employeeId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CHEMIST_APPROVAL_LIST_API,
        null,
        certificate,
        {divisionId:divisionId,
          employeeId:employeeId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUsePutChemistApprovalByChemistId = async ({
  certificate,
  signal,
  chemistId,
  managerId,
}) => {
  return rqCreateRequest(
    RQ_PUT_CHEMIST_APPROVAL_BY_CHEMIST_ID,
    null,
    certificate,
    {chemistId: chemistId, managerId: managerId},
    signal,
  );
};

export const RQUsePutChemistRejectByChemistId = async ({
  certificate,
  signal,
  chemistId,
  managerId,
}) => {
  return rqCreateRequest(
    RQ_PUT_CHEMIST_REJECT_BY_CHEMIST_ID,
    null,
    certificate,
    {chemistId: chemistId, managerId: managerId},
    signal,
  );
};

export const RQUseGetChemistFindByChemistId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_chemist_find_chemist_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, chemistId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CHEMIST_FIND_BY_CHEMIST_ID,
        null,
        certificate,
        {chemistId :chemistId},
        signal,
      );
    },
    ...options,
  });
};
