export const RQ_GET_EVENT_TOPIC_LIST = {
  url: 'whatsappdata/list/<%= locationId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_WHATSAPP_DATA_BY_LOCATION_ID = {
  url: 'whatsappdata/dashboard/<%= locationId %>/<%= campaign %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_WHATSAPP_TOTAL_DML_LIST_API = {
  url: 'whatsappdata/total-dml-list/<%= locationId %>/<%= campaign %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_WHATSAPP_STATUS_DML_LIST_API = {
  url: 'whatsappdata/dml-list/<%= locationId %>/<%= campaign %>/<%= status %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_WHATSAPP_CHART_DATA = {
  url: 'whatsappdata/rxn-chart-data/<%= locationId %>/<%= campaign %>',
  method: 'GET',
  auth: true,
};
