import {useEffect, useState} from 'react';
import {MenuBuilder} from './MenuBuilder';
import {Drawer, Layout, Menu} from 'antd';
import * as PropTypes from 'prop-types';
import './SideMenu.less';
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {RQGetSideMenusData} from '@app/reactQuery/apis';
import {ROUTE_BASE_ROOT} from '@app/navigations/RoutePathConstant';
import {selectAuth} from '@app/redux/selectors';
import {getWindowDimensions} from '@app/utils';

const {Sider} = Layout;

const SideMenu = ({auth, menuClicked, setMenuClicked}) => {
  const [dimensions] = useState(getWindowDimensions());
  const [mobileView] = useState(dimensions.width < 768);

  const {data = []} = RQGetSideMenusData({
    certificate: auth?.certificate,
    userId: auth?.principal?.id,
    uiInterface: 'web',
  });

  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(
    location.pathname === ROUTE_BASE_ROOT || location.pathname === ''
      ? ROUTE_BASE_ROOT
      : location.pathname.split(ROUTE_BASE_ROOT)[1],
  );

  useEffect(() => {
    if (location) {
      if (currentPath !== location.pathname) {
        setCurrentPath(location.pathname);
      }
    }
  }, [location, currentPath]);

  if (mobileView) {
    return (
      <>
        <Drawer
          placement={'left'}
          visible={menuClicked}
          onClose={() => {
            setMenuClicked(false);
          }}
        >
          <Menu
            className={'menu'}
            mode={'inline'}
            defaultSelectedKeys={[currentPath]}
            items={MenuBuilder(data)
            }
          />
        </Drawer>
      </>
    );
  } else {
    return (
      <Sider
        className={'main-side-menu-container'}
        theme={'light'}
      >

        <Menu
          className={'menu'}
          mode={'inline'}
          defaultSelectedKeys={[currentPath]}
          items={MenuBuilder(data)
          }
        />
      </Sider>
    );
  }
};

SideMenu.propTypes = {
  auth: PropTypes.shape({
    principal: PropTypes.any.isRequired,
    certificate: PropTypes.string,
    appContext: PropTypes.string,
  }),
  menuClicked: PropTypes.bool,
  onClick: PropTypes.func,
  setMenuClicked: PropTypes.func,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SideMenu);
