import {RQ_GET_DIVISION_LIST_API} from '@app/reactQuery/apis/constants/rqDivisionAPIConstants';
import {
  RQ_GET_LOCATION_BY_DIVISION_DATA_API,
  RQ_GET_LOCATION_DATA_API,
  RQ_GET_LOCATION_TYPE,
  RQ_GET_LOCATION_TYPE_LEVEL,
} from '@app/reactQuery/apis/constants/rqLocationAPIConstants';
import {RQ_GET_TOWN_API, RQ_GET_TOWN_CATEGORY_FIND_BY_ID} from '@app/reactQuery/apis/constants/rqTownAPIConstants';
import {RQ_GET_STATE_API} from '@app/reactQuery/apis/constants/rqStateAPIConstants';
import {
  RQ_GET_BEATS_BY_LOCATION_ID_API,
  RQ_GET_BRAND_LIST_API,
  RQ_GET_CALL_DAY_BY_TYPE_API,
  RQ_GET_CHEMIST_BY_TYPE_API,
  RQ_GET_CHEMIST_CUSTOMER_TYPE_BY_TYPE_API,
  RQ_GET_CHEMIST_TYPE_BY_TYPE_API,
  RQ_GET_DOCTOR_BY_TYPE_API,
  RQ_GET_DOCTOR_DEGREE_BY_TYPE_API,
  RQ_GET_HOSPITAL_BY_TYPE_API,
  RQ_GET_HOSPITAL_TYPE_BY_TYPE_API,
  RQ_GET_MAJOR_FOCUS_AREA_TYPE_API,
  RQ_GET_SPORTS_BY_TYPE_API,
} from '@app/reactQuery/apis/constants';
import {RQ_GET_STATUS_BY_TYPE_API} from '@app/reactQuery/apis/constants/rqStockistAPIConstant';

export const SystemSelectNames = {
  DIVISION : {name: 'rq_division', api: RQ_GET_DIVISION_LIST_API},
  BRAND : {name: 'rq_brand', api: RQ_GET_BRAND_LIST_API},
  LOCATION : {name: 'rq_location', api: RQ_GET_LOCATION_DATA_API, misc: {divisionId: '#divisionId#'}},
  LOCATION_LIST : {name: 'rq_location', api: RQ_GET_LOCATION_BY_DIVISION_DATA_API, misc: {divisionId: '#divisionId#'}},
  LOCATION_TYPE : {name: 'rq_location_type', api: RQ_GET_LOCATION_TYPE},
  LOCATION_TYPE_LEVEL : {name: 'rq_location_type_level', api: RQ_GET_LOCATION_TYPE_LEVEL, misc:{locationTypeId:'#type#'}},
  STATE : {name: 'rq_state', api: RQ_GET_STATE_API},
  TOWN : {name: 'rq_town', api: RQ_GET_TOWN_API, misc: {stateId: '#stateId#'}},
  TOWN_CATEGORY : {name: 'rq_town_category', api: RQ_GET_TOWN_CATEGORY_FIND_BY_ID, misc:{type: 'TOWN_CATEGORY'}},
  CLASSIFICATION : {name: 'rq_stockist_classification', api: RQ_GET_STATUS_BY_TYPE_API, misc: {type: 'STOCKIST_CLASSIFICATION'}},
  HOSPITAL_CLASSIFICATION : {name: 'rq_stockist_classification', api: RQ_GET_HOSPITAL_BY_TYPE_API},
  CUSTOMER_TYPE : {name: 'rq_customer_type', api: RQ_GET_CHEMIST_BY_TYPE_API},
  DOCTOR : {name: 'rq_customer_type', api: RQ_GET_DOCTOR_BY_TYPE_API, misc: {locationId: 'locationId'}},
  HOSPITAL_TYPE : {name: 'rq_stockist_classification', api: RQ_GET_HOSPITAL_TYPE_BY_TYPE_API, misc: {type: 'professorHODorEqui'}},
  SPORTS : {name: 'rq_sports', api: RQ_GET_SPORTS_BY_TYPE_API, misc: {type: 'SPORTS'}},
  SOCIAL_MEDIA : {name: 'rq_sports', api: RQ_GET_SPORTS_BY_TYPE_API, misc: {type: 'SOCIAL_MEDIA'}},
  MEAL_PREFERENCE : {name: 'rq_meal', api: RQ_GET_SPORTS_BY_TYPE_API, misc: {type: 'MEAL_PREFERENCE'}},
  DOCTOR_CLASSIFICATION : {name: 'rq_doctor_classification', api: RQ_GET_STATUS_BY_TYPE_API, misc: {type: 'DOCTOR_CLASSIFICATION'}},
  CHEMIST_CLASSIFICATION : {name: 'rq_chemist_classification', api: RQ_GET_CHEMIST_TYPE_BY_TYPE_API, misc: {type: 'CHEMIST_CLASSIFICATION'}},
  STATUS : {name: 'rq_status', api: RQ_GET_STATUS_BY_TYPE_API, misc: {type: 'STOCKIST_STATUS'}},
  GENDER : {name: 'rq_gender', api: RQ_GET_STATUS_BY_TYPE_API, misc: {type: 'GENDER'}},
  SPECIALITY : {name: 'rq_speciality', api: RQ_GET_STATUS_BY_TYPE_API, misc: {type: 'DOCTOR_SPECIALTY'}},
  BEAT : {name: 'rq_beat_by_location_id', api: RQ_GET_BEATS_BY_LOCATION_ID_API, misc: {locationId: 'locationId'}},
  MAJOR_FOCUSED_AREA : {name: 'rq_major_focus_area', api: RQ_GET_MAJOR_FOCUS_AREA_TYPE_API, misc: {type: 'MAJOR_FOCUSED_AREA'}},
  DOCTOR_DEGREE : {name: 'rq_doctor_degrees', api: RQ_GET_DOCTOR_DEGREE_BY_TYPE_API, misc: {type: 'DOCTOR_DEGREE'}},
  WEEK_DAYS: {name: 'rq_week_days', api: RQ_GET_CALL_DAY_BY_TYPE_API, misc: {type: 'WEEK_DAYS'}},
  CHEMIST_CUSTOMER_TYPE: {name: 'rq_chemist_customer_type', api: RQ_GET_CHEMIST_CUSTOMER_TYPE_BY_TYPE_API, misc: {type: 'CHEMIST_CUSTOMER_TYPE'}},
};
