import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_STOCKIST, ROUTE_EDIT_STOCKIST, ROUTE_STOCKIST} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Stockist = lazy(() =>
  import('../../../screens/Stockiest')
    .then(module => ({default: module.Stockist})));

const CreateStockist = lazy(() =>
  import('../../../screens/Stockiest')
    .then(module => ({default: module.CreateStockist})));

const UpdateStockist = lazy(() =>
  import('../../../screens/Stockiest')
    .then(module => ({default: module.UpdateStockist})));

const StockistNavigation = [
  {
    path: ROUTE_STOCKIST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Stockist />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_STOCKIST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateStockist />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_STOCKIST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateStockist />
      </Suspense>
    ),
  },
];

export default StockistNavigation;
