import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_UNLOCKING_HISTORY_TREND} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const UnlockingHistoryTrend = lazy(() =>
  import('../../../screens/UnlockingHistoryTrend')
    .then(module => ({default: module.UnlockingHistoryTrend})));

const UnlockingHistoryTrendNavigation = [
  {
    path: ROUTE_UNLOCKING_HISTORY_TREND,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UnlockingHistoryTrend />
      </Suspense>
    ),
  },
];

export default UnlockingHistoryTrendNavigation;
