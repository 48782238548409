export const RQ_GET_LEAVE_DETAILS_API = {
  url:'leaves/by-user/<%= employeeCode %>/<%= year %>',
  method:'GET',
  auth:true,
};

export const RQ_SAVE_LEAVE_HO_REMARKS = {
  url:'leaves/setRemarkByHo/<%= leaveId %>/<%= remarks %>',
  method:'PUT',
  auth:true,
};
