import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';
import {RQ_GET_MENUS_USER_ID_API} from '@app/reactQuery/apis/constants';

export const RQCommonListData = (payload, options = {}) => {
  return useQuery({
    queryKey: [payload?.name?.toLowerCase(), payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, userId, locationId, api, misc, filter = {}} = queryKey[1];
      return rqCreateSearchRequest(
        api,
        null,
        certificate,
        {...misc, userId: userId, locationId: locationId},
        signal,
        filter,
      );
    },

    ...options,
  });
};

export const RQGetSideMenusData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_side_menu_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, uiInterface} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_MENUS_USER_ID_API,
        null,
        certificate,
        {uiInterface:uiInterface},
        signal,
      );
    },
    ...options,
  });
};
