import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';

const {TextArea} = Input;

const TextAreaAttribute = ({attributeMetadata}) => {

  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      rules={attributeMetadata.validation_rules}
    >
      <TextArea
        placeholder={attributeMetadata?.placeholder}
        disabled={!attributeMetadata.editable}
        autoSize={{minRows: 2, maxRows: 6}}
      />
    </Form.Item>
  );
};

TextAreaAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }).isRequired,
};

export default TextAreaAttribute;
