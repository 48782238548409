import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_HOSPITAL_MASTER_UPDATION,
  ROUTE_EDIT_HOSPITAL_MASTER_UPDATION,
  ROUTE_HOSPITAL_MASTER_UPDATION,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const HospitalMasterUpdation = lazy(() =>
  import('../../../screens/HospitalMasterUpdation')
    .then(module => ({default: module.HospitalMasterUpdation})));

const CreateHospitalMasterUpdation = lazy(() =>
  import('../../../screens/HospitalMasterUpdation')
    .then(module => ({default: module.CreateHospitalMasterUpdation})));

const UpdateHospital = lazy(() =>
  import('../../../screens/HospitalMasterUpdation')
    .then(module => ({default: module.UpdateHospital})));

const HospitalMasterUpdationNavigation = [
  {
    path: ROUTE_HOSPITAL_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <HospitalMasterUpdation />
      </Suspense>
    ),
  },

  {
    path: ROUTE_ADD_HOSPITAL_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateHospitalMasterUpdation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_HOSPITAL_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateHospital />
      </Suspense>
    ),
  },
];
export default HospitalMasterUpdationNavigation;
