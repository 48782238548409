import React, {Suspense, lazy} from 'react';
import {ROUTE_WHATSAPP_DASHBOARD} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const WhatsappDelivery = lazy(() =>
  import('../../../screens/WhatsappDeliveryDashboard')
    .then(module => ({default: module.WhatsappDeliveryDashboard})));

const WhatsappDeliveryDashboardNavigation = [
  {
    path: ROUTE_WHATSAPP_DASHBOARD,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <WhatsappDelivery />
      </Suspense>
    ),
  },
];

export default WhatsappDeliveryDashboardNavigation;
