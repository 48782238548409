import React from 'react';
import PropTypes from 'prop-types';
import {Form, Select} from 'antd';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import {RQUseGetLocationListByDivisionIdAndTypeId} from '@app/reactQuery/apis';

const SingleSelectDependentLocationListAttribute = ({auth, attributeMetadata, formValues}) => {
  const divisionId = formValues[attributeMetadata?.input_parent_division_id];
  const typeId = formValues[attributeMetadata?.input_parent_type_id];

  const {data} = RQUseGetLocationListByDivisionIdAndTypeId({
    certificate: auth?.certificate,
    divisionId: divisionId,
    typeId: typeId,
  }
  , {
    enabled: !!(divisionId && typeId),
  });
  // const dependentData = (attributeMetadata?.input_search_enable)
  //   ? []
  //   : isArray(data)
  //     ? data
  //     : data
  //       ? [data]
  //       : [];
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      rules={attributeMetadata.validation_rules}
    >
      <Select
        showSearch
        filterOption={filterOption}
        placeholder={attributeMetadata?.placeholder}
        disabled={!attributeMetadata.editable}
        options={data?.map((object, index) => {
          return {
            value: object.id.id,
            label: object.parent.name,
          };
        })}
      />
    </Form.Item>
  );
};

SingleSelectDependentLocationListAttribute.propTypes = {
  auth: PropTypes.shape({
    certificate: PropTypes.string.isRequired,
  }).isRequired,
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    partName:PropTypes.string,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    input_search_enable: PropTypes.bool,
    input_parent_division_id: PropTypes.string.isRequired,
    input_parent_type_id: PropTypes.string.isRequired,
  }).isRequired,
  formValues: PropTypes.object,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SingleSelectDependentLocationListAttribute);
