import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_DOCTOR_MASTER_UPDATION, ROUTE_ADD_DOCTOR_UID_MASTER_UPDATION, ROUTE_DASH,
  ROUTE_DOCTOR_360,
  ROUTE_DOCTOR_MASTER_UPDATION,
  ROUTE_EDIT_DOCTOR_MASTER_UPDATION,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const DoctorMasterUpdation = lazy(() =>
  import('../../../screens/DoctorMasterUpdation')
    .then(module => ({default: module.DoctorMasterUpdation})));

const CreateDoctor = lazy(() =>
  import('../../../screens/DoctorMasterUpdation')
    .then(module => ({default: module.CreateDoctor})));

const UpdateDoctor = lazy(() =>
  import('../../../screens/DoctorMasterUpdation')
    .then(module => ({default: module.UpdateDoctor})));

const Doctor360 = lazy(() =>
  import('../../../screens/DoctorMasterUpdation')
    .then(module => ({default: module.Doctor360})));

const Dash = lazy(() =>
  import('../../../screens/DoctorMasterUpdation')
    .then(module => ({default: module.Dash})));

const CreateDoctorUID = lazy(() =>
  import('../../../screens/DoctorMasterUpdation')
    .then(module => ({default: module.CreateDoctorFromUID})));

const DoctorMasterUpdationNavigation = [
  {
    path: ROUTE_DOCTOR_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DoctorMasterUpdation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_DOCTOR_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateDoctor />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_DOCTOR_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateDoctor />
      </Suspense>
    ),
  },
  {
    path: ROUTE_DOCTOR_360,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Doctor360 />
      </Suspense>
    ),
  },
  {
    path: ROUTE_DASH,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Dash />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_DOCTOR_UID_MASTER_UPDATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateDoctorUID />
      </Suspense>
    ),
  },
];

export default DoctorMasterUpdationNavigation;
