import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Space, Upload, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {useMutation} from '@tanstack/react-query';
import {RQUploadDocument} from '@app/reactQuery/apis';
import {connect} from 'react-redux';
import {selectAuth} from '@app/redux/selectors';
import {FilePreview} from '@app/components/common/FormBuilder/attributeTypes/standard/FileAttribute/FilePreview';
import {LoadingSpinner} from '@app/components/common/LoadingSpinner';

const FileAttribute = ({auth, attributeMetadata, documentObjectId}) => {
  const form = Form.useFormInstance();

  const isVertical = attributeMetadata?.layout === 'vertical';

  const mutationUploadDocument = useMutation({
    mutationFn: payload => RQUploadDocument(payload),
    onSuccess: (response, payload) => {
      form.setFieldValue(
        attributeMetadata.id,
        [...form.getFieldValue(attributeMetadata.id), {documentId: response?.id}],
      );
      return true;
    },
    onError: () => {
      return message.error('Failed to upload document, please try again');
    },
  });

  const uploadDocument = file => {
    mutationUploadDocument.mutate({
      tokenData: auth?.tokenData,
      formData: {
        file: file,
        objectId: documentObjectId,
        documentObjectType: attributeMetadata.documentObjectType,
        documentEntity: attributeMetadata.documentEntity,
      },
    });
    return false;
  };

  const removeDocument = fileIdToRemove => {
    form.setFieldValue(
      attributeMetadata.id,
      [...form.getFieldValue(attributeMetadata.id).filter(document => document?.documentId !== fileIdToRemove)],
    );
  };

  return (
    <Form.Item
      label={attributeMetadata.label}
      wrapperCol={{
        span: isVertical
          ? ((isVertical && attributeMetadata?.vertical_value_col_span)
            ? attributeMetadata?.vertical_value_col_span
            : 24)
          : 20,
      }}
      labelCol={{
        span: isVertical ? 24 : 4,
        ...((isVertical && attributeMetadata?.vertical_label_height)
          ? {style: {height: attributeMetadata?.vertical_label_height}}
          : {}),
      }}
    >
      {mutationUploadDocument?.isPending && <LoadingSpinner />}
      <Form.Item
        name={[attributeMetadata.id]}
        rules={attributeMetadata.validation_rules}
        hidden={true}
      >
        <Input type={'hidden'} />
      </Form.Item>
      <Space direction={'vertical'} className={'w-full'}>
        <Upload
          beforeUpload={file => uploadDocument(file)}
          fileList={[]}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        {
          form.getFieldValue(attributeMetadata.id)?.map(document => {
            return (
              <Col span={24} key={document?.documentId}>
                <FilePreview documentId={document?.documentId} removeDocument={removeDocument} />
              </Col>
            );
          })
        }
      </Space>
    </Form.Item>
  );
};

FileAttribute.propTypes = {
  auth: PropTypes.object.isRequired,
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    layout: PropTypes.string,
    placeholder: PropTypes.string,
    vertical_value_col_span: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical_label_height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    documentEntity: PropTypes.string.isRequired,
    documentObjectType: PropTypes.string.isRequired,
  }).isRequired,
  documentObjectId: PropTypes.string.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(FileAttribute);
