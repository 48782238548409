import {
  RQ_GET_ADD_SPECIAL_FARE_BY_YEAR_MONTH_API,
  RQ_POST_ADD_SPECIAL_FARE_BY_EMPLOYEE_CODE_YEAR_MONTH_API,
} from '@app/reactQuery/apis/constants';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';

export const RQPostAddSpecialFareByEmployeeCodeAndYearMonth = async ({
  certificate,
  signal,
  employeeCode,
  yyyyMm,

}) => {
  return rqCreateRequest(
    RQ_POST_ADD_SPECIAL_FARE_BY_EMPLOYEE_CODE_YEAR_MONTH_API,
    null,
    certificate,
    {employeeCode: employeeCode, yyyyMm:yyyyMm},
    signal,
  );
};

export const RQUseGetAddSpecialFAreByYearMonth = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_add_special_by_year_month', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, yyyyMm} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_ADD_SPECIAL_FARE_BY_YEAR_MONTH_API,
        null,
        certificate,
        {yyyyMm: yyyyMm},
        signal,
      );
    },
    ...options,
  });
};
