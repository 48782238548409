import {createReducer} from './reducerUtils';
import {
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESSFUL,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
  LOGIN_FAIL,
  LOGIN_SUCCESSFUL, LOGOUT_SUCCESSFUL,
  RESET_AUTH_DATA,
  RESET_DATA,
  UPDATE_USER_PROFILE_SUCCESSFUL,
} from '../actionConstants';
import {assign, merge} from 'lodash';

const initialState = {
  login: {
    username: '',
    error: {message: '', errorCode: ''},
  },
  certificate: '',
  principal:'',
  loggedIn: false,
};

const loginSuccessful = (state = initialState, payload) => {
  return merge({}, state, {
    loggedIn: true,
    certificate: payload?.certificate,
    principal:payload?.principal,
    login: {error: {message: '', errorCode: ''}},
  });
};

const loginFail = (state = initialState, payload) => {
  return merge({}, state, {
    loggedIn: false,
    login: {error: {...payload}},
  });
};

const logoutSuccessful = (state = initialState, payload) => {
  return merge({}, state, {loggedIn: false});
};

const getUserProfileSuccessful = (state = initialState, payload) => {
  return assign({}, state, {user: {...payload}, userProfileUpdated: true});
};

const getUserProfileFail = (state = initialState, payload) => {
  return assign({}, state, {
    user: {error: {...payload}},
    userProfileUpdated: false,
  });
};

const updateUserProfileSuccessful = (state = initialState, payload) => {
  return assign({}, state, {
    userProfileUpdated: true,
  });
};

const resetAuthData = (state = initialState) => {
  return initialState;
};

export default createReducer(initialState, {
  [RESET_DATA]: resetAuthData,
  [RESET_AUTH_DATA]: resetAuthData,
  [IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL]: loginSuccessful,
  [LOGIN_SUCCESSFUL]: loginSuccessful,
  [LOGIN_FAIL]: loginFail,
  [LOGOUT_SUCCESSFUL]: logoutSuccessful,
  [GET_USER_PROFILE_SUCCESSFUL]: getUserProfileSuccessful,
  [GET_USER_PROFILE_FAIL]: getUserProfileFail,
  [UPDATE_USER_PROFILE_SUCCESSFUL]: updateUserProfileSuccessful,
});
