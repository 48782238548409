export const RQ_GET_STATE_API = {
  url: 'state/getAll',
  method: 'GET',
  auth: true,
};

export const RQ_GET_STATE_FIND_BY_ID = {
  url: 'state/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_STATE_ALL_FIELDS_API = {
  url: 'state/update',
  method: 'PUT',
  auth: true,
};

export const RQ_CREATE_STATE_API = {
  url: 'state/create',
  method: 'PUT',
  auth: true,
};
