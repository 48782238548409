import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_EXPENSE_VIEW, ROUTE_NEW_EXPENSE_VIEW} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Expense = lazy(() =>
  import('../../../screens/Expense')
    .then(module => ({default: module.Expense})));

const NewExpense = lazy(() =>
  import('../../../screens/NewExpense')
    .then(module => ({default: module.NewExpense})),
);

const ExpenseNavigation = [
  {
    path: ROUTE_EXPENSE_VIEW,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Expense />
      </Suspense>
    ),
  },
  {
    path: ROUTE_NEW_EXPENSE_VIEW,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <NewExpense />
      </Suspense>
    ),
  },
];

export default ExpenseNavigation;
