import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_BEAT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const AddBeat = lazy(() =>
  import('../../../screensComponents/Organization/Beats/AddBeat')
    .then(module => ({default: module.AddBeat})));

const AddBeatNavigation = [
  {
    path: ROUTE_ADD_BEAT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddBeat />
      </Suspense>
    ),
  },
];

export default AddBeatNavigation;
