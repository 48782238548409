import React from 'react';
import {useNavigation} from 'react-router-dom';
import {SideMenu} from '../SideMenu';
import {ContentLayout} from '../ContentLayout';
import {Layout} from 'antd';
import {HeaderLayout} from '../HeaderLayout';

const MainLayout = () => {
  const navigation = useNavigation();

  return (
    <Layout className={'h-screen'}>
      <SideMenu />
      <Layout>
        <HeaderLayout />
        <ContentLayout navigation={navigation} />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
