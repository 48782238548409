import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_DIVISION_API,
  RQ_GET_DIVISION_FIND_BY_ID,
  RQ_GET_DIVISION_LIST_API,
  RQ_UPDATE_DIVISION_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants/rqDivisionAPIConstants';

export const RQUseGetDivisionListData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_division_list_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DIVISION_LIST_API,
        null,
        certificate,
        null,
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDivisionFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_division_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DIVISION_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutCreateDivision = ({division, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_DIVISION_API,
    JSON.stringify({...division}),
    certificate,
    null,
    signal,
  );
};

export const RQPutDivisionUpdateAllField = async ({
  division,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_DIVISION_ALL_FIELDS_API,
    JSON.stringify({...division, id:id}),
    certificate,
    null,
    signal,
  );
};
