import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_LAST_WORKING_DAY} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const LastWorkingDay = lazy(() =>
  import('../../../screens/LastWorkingDay')
    .then(module => ({default: module.LastWorkingDay})));

const LastWorkingDayNavigation = [
  {
    path: ROUTE_LAST_WORKING_DAY,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <LastWorkingDay />
      </Suspense>
    ),
  },
];

export default LastWorkingDayNavigation;
