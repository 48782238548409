import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_DOCTOR_APPROVAL, ROUTE_VIEW_DOCTOR_APPROVAL} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const DoctorApproval = lazy(() =>
  import('../../../screens/DoctorApproval')
    .then(module => ({default: module.DoctorApproval})));

const DoctorApprovalReject = lazy(() =>
  import('../../../screens/DoctorApproval')
    .then(module => ({default: module.UpdateApproveRejectDoctor})));

const DoctorApprovalNavigation = [
  {
    path: ROUTE_DOCTOR_APPROVAL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DoctorApproval />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_DOCTOR_APPROVAL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DoctorApprovalReject />
      </Suspense>
    ),
  },
];

export default DoctorApprovalNavigation;
