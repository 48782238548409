import {rqCreateRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_DOCUMENT_DOWNLOAD_API,
  RQ_DOCUMENT_LOOKUP_API,
  RQ_UPLOAD_DOCUMENT_API,
} from '@app/reactQuery/apis/constants/rqDocumentAPIConstants';
import {useQuery} from '@tanstack/react-query';

export const RQUseGetDocumentInfo = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_document_lookup_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];
      return rqCreateRequest(
        RQ_DOCUMENT_LOOKUP_API,
        null,
        tokenData?.access_token,
        {id: id},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDocumentDownload = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_document_download_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, certificate} = queryKey[1];
      return rqCreateRequest(
        RQ_DOCUMENT_DOWNLOAD_API,
        null,
        certificate,
        {id: id},
        signal,
      );
    },
    ...options,
  });
};

export const RQUploadDocument = async ({
  formData,
  certificate,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPLOAD_DOCUMENT_API,
    JSON.stringify({...formData}),
    certificate,
    null,
    signal,
  );
};
