import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_DOCTOR_VISIT_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const DoctorVisitReports = lazy(() =>
  import('../../../screens/DoctorVisitReports')
    .then(module => ({default: module.DoctorVisitReports})));

const DoctorVisitReportsNavigation = [
  {
    path: ROUTE_DOCTOR_VISIT_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DoctorVisitReports />
      </Suspense>
    ),
  },
];

export default DoctorVisitReportsNavigation;
