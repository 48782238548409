import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_SECONDARY_COMPLIANCE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const SecondaryCompliance = lazy(() =>
  import('../../../screens/SecondaryCompliance')
    .then(module => ({default: module.SecondaryCompliance})));

const SecondaryComplianceNavigation = [
  {
    path: ROUTE_SECONDARY_COMPLIANCE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SecondaryCompliance />
      </Suspense>
    ),
  },
];

export default SecondaryComplianceNavigation;
