import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_BRAND_WISE_SPECIALITY_REPORT_API,
  RQ_EFFORT_HYGIENE_CALLS_AND_CALL_AVG_API,
  RQ_EFFORT_HYGIENE_DOCTOR_VISIT_API,
  RQ_EFFORT_HYGIENE_DR_MET_AND_COVERAGE_CHART_API,
  RQ_EFFORT_HYGIENE_MIRROR_STRATEGY_API,
  RQ_EFFORT_HYGIENE_TOTAL_DML_API, RQ_GET_DML_DOCTOR_COUNT_LIST_DETAILS_API,
  RQ_GET_DML_LIST_DETAILS_API,
  RQ_GET_FREQUENCY_DOCTOR_LIST_API,
  RQ_GET_MIRROR_DOCTOR_LIST_API, RQ_GET_MISSED_DML_LIST_API,
  RQ_GET_PRIMARY_COLLECTION_SECONDARY_STOCKIST_LIST_API,
  RQ_GET_SPECIALITY_WISE_DOCTOR_LIST_API,
  RQ_GET_SPECIALITY_WISE_RX_DOCTOR_LIST_API,
  RQ_PRIMARY_SECONDARY_RCPA_COLLECTION_API,
  RQ_RCPA_DML_LIST_API,
  RQ_RCPA_TREND_REPORT_API,
  RQ_REVIEW_DASHBOARD_EFFORT_DATA_API, RQ_REVIEW_DASHBOARD_RCPA_DATA_API,
  RQ_REVIEW_DASHBOARD_SALES_DATA_MTD_API,
  RQ_REVIEW_DASHBOARD_SALES_DATA_YTD_API,
  RQ_TGT_SALES_COLLECTION_API,
} from '@app/reactQuery/apis/constants';

export const RQBrandWiseSpecialityReport = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_brand_wise_speciality_report', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromMonth, toMonth, brandId, productId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_BRAND_WISE_SPECIALITY_REPORT_API,
        null,
        certificate,
        {divisionId: divisionId,
          fromMonth:fromMonth, toMonth: toMonth, brandId: brandId, productId: productId},
        signal,
      );
    },
    ...options,
  });
};

export const RQRcpaTrendReport = (payload, options = {}) => {
  console.log('PlayLoad', payload);
  return useQuery({
    queryKey:['rq_rcpa_trend_report', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromMonth, toMonth, brandId, productId, specialityId, categoryId, segment, periodType} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_RCPA_TREND_REPORT_API,
        null,
        certificate,
        {divisionId: divisionId,
          fromMonth:fromMonth, toMonth: toMonth, brandId: brandId, productId: productId, specialityId: specialityId,
          categoryId: categoryId, segment: segment, periodType: periodType},
        signal,
      );
    },
    ...options,
  });
};

export const RQEffortHygieneDoctorVisitReport = (payload, options = {}) => {
  console.log('PlayLoad', payload);
  return useQuery({
    queryKey:['rq_effort_hygiene_doctor_visit_report', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDate, toDate, specialityId, categoryId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_EFFORT_HYGIENE_DOCTOR_VISIT_API,
        null,
        certificate,
        {divisionId: divisionId, fromDate: fromDate, toDate: toDate, specialityId: specialityId, categoryId: categoryId},
        signal,
      );
    },
    ...options,
  });
};

export const RQEffortHygieneMirrorStrategyReport = (payload, options = {}) => {
  console.log('PlayLoad', payload);
  return useQuery({
    queryKey:['rq_effort_hygiene_mirror_strategy_report', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDate, toDate, specialityId, categoryId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_EFFORT_HYGIENE_MIRROR_STRATEGY_API,
        null,
        certificate,
        {divisionId: divisionId, fromDate: fromDate, toDate: toDate, specialityId: specialityId, categoryId: categoryId},
        signal,
      );
    },
    ...options,
  });
};

export const RQEffortHygieneTotalDMLReport = (payload, options = {}) => {
  console.log('PlayLoad', payload);
  return useQuery({
    queryKey:['rq_effort_hygiene_total_dml_report', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, specialityId, categoryId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_EFFORT_HYGIENE_TOTAL_DML_API,
        null,
        certificate,
        {divisionId: divisionId, specialityId: specialityId, categoryId: categoryId},
        signal,
      );
    },
    ...options,
  });
};

export const RQTgtSalesCollectionReport = (payload, options = {}) => {
  console.log('PlayLoad', payload);
  return useQuery({
    queryKey:['rq_tgt_sales_collection_report', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromMonth, toMonth, brandId, productId, periodType} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_TGT_SALES_COLLECTION_API,
        null,
        certificate,
        {divisionId: divisionId,
          fromMonth:fromMonth, toMonth: toMonth, brandId: brandId, productId: productId, periodType: periodType},
        signal,
      );
    },
    ...options,
  });
};

export const RQPrimarySecondaryRcpaCollectionReport = (payload, options = {}) => {
  console.log('PlayLoad', payload);
  return useQuery({
    queryKey:['rq_primary_secondary_rcpa_collection_report', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromMonth, toMonth, brandId, productId, periodType} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_PRIMARY_SECONDARY_RCPA_COLLECTION_API,
        null,
        certificate,
        {divisionId: divisionId,
          fromMonth:fromMonth, toMonth: toMonth, brandId: brandId, productId: productId, periodType: periodType},
        signal,
      );
    },
    ...options,
  });
};

export const RQEffortHygieneDrMetAndCoverageChart = (payload, options = {}) => {
  console.log('PlayLoad', payload);
  return useQuery({
    queryKey:['rq_effort_hygiene_dr_met_and_coverage_chart', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDate, toDate, specialityId, categoryId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_EFFORT_HYGIENE_DR_MET_AND_COVERAGE_CHART_API,
        null,
        certificate,
        {divisionId: divisionId, fromDate: fromDate, toDate: toDate, specialityId: specialityId, categoryId: categoryId},
        signal,
      );
    },
    ...options,
  });
};

export const RQEffortHygieneCallsAndCallAvgChart = (payload, options = {}) => {
  console.log('PlayLoad', payload);
  return useQuery({
    queryKey:['rq_effort_hygiene_call_and_call_avg_chart', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDate, toDate, specialityId, categoryId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_EFFORT_HYGIENE_CALLS_AND_CALL_AVG_API,
        null,
        certificate,
        {divisionId: divisionId, fromDate: fromDate, toDate: toDate, specialityId: specialityId, categoryId: categoryId},
        signal,
      );
    },
    ...options,
  });
};

export const RQGetDmlListDetails = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_all_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, speciality, classification} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DML_LIST_DETAILS_API,
        null,
        certificate,
        {locationId: locationId},
        signal,
        {classification: classification, speciality: speciality},
      );
    },
    ...options,
  });
};

export const RQGetDmlDoctorCountList = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_dml_doctor_count_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, fromDate, toDate, classification, speciality} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DML_DOCTOR_COUNT_LIST_DETAILS_API,
        null,
        certificate,
        {locationId: locationId, fromDate: fromDate, toDate: toDate},
        signal,
        {classification: classification, speciality: speciality},
      );
    },
    ...options,
  });
};

export const RQGetFrequencyDoctorListDetails = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_frequency_all_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, visitCount, fromDate, toDate, classification, speciality} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_FREQUENCY_DOCTOR_LIST_API,
        null,
        certificate,
        {locationId: locationId, visitCount: visitCount, fromDate: fromDate, toDate: toDate},
        signal,
        {classification: classification, speciality: speciality},
      );
    },
    ...options,
  });
};

export const RQGetMirrorDoctorListDetails = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_mirror_all_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, visitCount, fromDate, toDate, classification, speciality} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_MIRROR_DOCTOR_LIST_API,
        null,
        certificate,
        {locationId: locationId, visitCount: visitCount, fromDate: fromDate, toDate: toDate},
        signal,
        {classification: classification, speciality: speciality},
      );
    },
    ...options,
  });
};

export const RQGetMissedDoctorListDetails = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_missed_all_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, fromDate, toDate, classification, speciality} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_MISSED_DML_LIST_API,
        null,
        certificate,
        {locationId: locationId, fromDate: fromDate, toDate: toDate},
        signal,
        {classification: classification, speciality: speciality},
      );
    },
    ...options,
  });
};

export const RQSpecialityWiseDoctorListSelect = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_all_speciality_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, speciality, fromMonth, toMonth} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SPECIALITY_WISE_DOCTOR_LIST_API,
        null,
        certificate,
        {locationId: locationId, fromMonth: fromMonth, toMonth: toMonth},
        signal,
        {speciality: speciality},
      );
    },
    ...options,
  });
};

export const RQSpecialityWiseRxDoctorListSelect = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_all_speciality_rx_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, speciality, fromMonth, toMonth, brandId, productId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SPECIALITY_WISE_RX_DOCTOR_LIST_API,
        null,
        certificate,
        {locationId: locationId, speciality: speciality, fromDate: fromMonth, toDate: toMonth},
        signal,
        {brand: brandId, product: productId},
      );
    },
    ...options,
  });
};

export const RQRcpaDoctorListSelect = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_all_rcpa_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, fromMonth, toMonth, periodType, brandId, productId, specialityId, categoryId, segment} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_RCPA_DML_LIST_API,
        null,
        certificate,
        {locationId: locationId, fromMonth: fromMonth, toMonth: toMonth, periodType: periodType},
        signal,
        {brand: brandId, product: productId, classification: categoryId, speciality: specialityId, segment: segment},
      );
    },
    ...options,
  });
};

export const RQPrimaryCollectionSecondaryStockistListSelect = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_primary_collection_secondary_stockist_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, fromMonth, toMonth, brandId, productId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_PRIMARY_COLLECTION_SECONDARY_STOCKIST_LIST_API,
        null,
        certificate,
        {locationId: locationId, fromMonth: fromMonth, toMonth: toMonth},
        signal,
        {brand: brandId, product: productId},
      );
    },
    ...options,
  });
};

export const RQReviewDashboardSalesDataMTD = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_review_dashboard_sales_data_mtd', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, monthYear, periodType} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_REVIEW_DASHBOARD_SALES_DATA_MTD_API,
        null,
        certificate,
        {locationId: locationId, monthYear: monthYear, periodType: periodType},
        signal,
      );
    },
    ...options,
  });
};

export const RQReviewDashboardSalesDataYTD = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_review_dashboard_sales_data_ytd', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, monthYear} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_REVIEW_DASHBOARD_SALES_DATA_YTD_API,
        null,
        certificate,
        {locationId: locationId, monthYear: monthYear},
        signal,
      );
    },
    ...options,
  });
};

export const RQReviewDashboardRcpaData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_review_dashboard_rcpa_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, monthYear, periodType} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_REVIEW_DASHBOARD_RCPA_DATA_API,
        null,
        certificate,
        {locationId: locationId, monthYear: monthYear, periodType: periodType},
        signal,
      );
    },
    ...options,
  });
};

export const RQReviewDashboardEffortData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_review_dashboard_effort_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, fromDate, toDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_REVIEW_DASHBOARD_EFFORT_DATA_API,
        null,
        certificate,
        {locationId: locationId, fromDate: fromDate, toDate: toDate},
        signal,
      );
    },
    ...options,
  });
};
