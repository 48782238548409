import {lazy} from 'react';
import {ROUTE_PRI_VS_SEC_VS_RCPA_REPORT} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import React, {Suspense} from 'react';
import {Spin} from 'antd';

const PriVsSecVsRcpaReport = lazy(() => import('../../../screens/PriVsSecVsRcpaReport')
  .then(module => ({default: module.PriVsSecVsRcpaReport})));

const PriVsSecVsRcpaReportNavigation = [
  {
    path: ROUTE_PRI_VS_SEC_VS_RCPA_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <PriVsSecVsRcpaReport />
      </Suspense>
    ),
  },
];

export default PriVsSecVsRcpaReportNavigation;
