export const RQ_GET_DOCTOR_APPROVAL_LIST_API = {
  url: 'doctor/approval-list/<%= employeeId %>/<%= divisionId %>',
  method: 'GET',
  auth: true,
};

export const RQ_PUT_DOCTOR_APPROVAL_BY_DOCTOR_ID = {
  url: 'doctor/approve/<%= doctorId  %>/<%= managerId  %>/approve',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_DOCTOR_REJECT_BY_DOCTOR_ID = {
  url: 'doctor/approve/<%= doctorId  %>/<%= managerId  %>/reject',
  method: 'PUT',
  auth: true,
};
