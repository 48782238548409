import {ROUTE_MANAGER_EXPENSE_APPROVAL_SEARCH} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';

const ExpenseApprovalSearch = lazy(() =>
  import('../../../screens/ExpenseApprovalSearch')
    .then(module => ({default: module.ExpenseApprovalSearch})));

const ExpenseApprovalSearchNavigation = [{
  path: ROUTE_MANAGER_EXPENSE_APPROVAL_SEARCH,
  loader: authLoader,
  element: (
    <Suspense fallback={<Spin />}>
      <ExpenseApprovalSearch />
    </Suspense>

  ),
},
];

export default ExpenseApprovalSearchNavigation;
