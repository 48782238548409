import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ENTRY_SEARCH, ROUTE_VIEW_STOCKIST} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';

const Entry = lazy(() =>
  import('@app/screens/SecondarySales/Entry')
    .then(module => ({default: module.Entry})));

const ViewStockist = lazy(() =>
  import('@app/screens/SecondarySales/ViewStockist')
    .then(module => ({default: module.ViewStockist})));

const EntryNavigation = [
  {
    path: ROUTE_ENTRY_SEARCH,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Entry />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_STOCKIST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewStockist />
      </Suspense>
    ),
  },
];

export default EntryNavigation;
