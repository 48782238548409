import React from 'react';
import {Button, Col, Popover, Typography} from 'antd';
import {MyProfile} from '@app/screensComponents/HomeScreen/MainHome/HeaderRightButton/MyProfile';
import {ChangePassword} from '@app/screensComponents/HomeScreen/MainHome/HeaderRightButton/ChangePassword';
import {LogoutButton} from '@app/screensComponents/HomeScreen/MainHome/HeaderLayout/LogoutButton';
import PropTypes from 'prop-types';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';

const HeaderRightButton = ({auth}) => {
  const content = (
    <Col className={'flex justify-center items-center w-full'}>
      <MyProfile />
      <ChangePassword />
      <LogoutButton />
    </Col>
  );

  return (
    <Popover placement={'bottomRight'} content={content} trigger={'click'}>
      <Button type={'link'}>
        <Typography className={'text-lg w-full text-white'}>
          {auth?.user?.employee?.name}
        </Typography>
      </Button>
    </Popover>
  );
};

HeaderRightButton.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(HeaderRightButton);
