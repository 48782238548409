import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_MANAGE_HOLIDAYS} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const ManageHolidays = lazy(() =>
  import('../../../screens/ManageHolidays')
    .then(module => ({default: module.ManageHolidays})));

const ManageHolidaysNavigation = [
  {
    path: ROUTE_MANAGE_HOLIDAYS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ManageHolidays />
      </Suspense>
    ),
  },
];

export default ManageHolidaysNavigation;
