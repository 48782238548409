import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_USER} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const User = lazy(() =>
  import('../../../screens/User')
    .then(module => ({default: module.User})));

const UserNavigation = [
  {
    path: ROUTE_USER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <User />
      </Suspense>
    ),
  },
];

export default UserNavigation;
