import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_DAILY_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const DailyReports = lazy(() =>
  import('../../../screens/DailyReports')
    .then(module => ({default: module.DailyReports})));

const DailyReportsNavigation = [
  {
    path: ROUTE_DAILY_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DailyReports />
      </Suspense>
    ),
  },
];

export default DailyReportsNavigation;
