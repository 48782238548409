import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_EVENT_TOPIC_LIST, RQ_GET_WHATSAPP_CHART_DATA,
  RQ_GET_WHATSAPP_DATA_BY_LOCATION_ID, RQ_GET_WHATSAPP_STATUS_DML_LIST_API,
  RQ_GET_WHATSAPP_TOTAL_DML_LIST_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetEventTopicList = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_event_topic_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_EVENT_TOPIC_LIST,
        null,
        certificate,
        {locationId: locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetWhatsappDataByLocationId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_whatsapp_data_by_location_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, campaign} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_WHATSAPP_DATA_BY_LOCATION_ID,
        null,
        certificate,
        {locationId: locationId, campaign: campaign},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetWhatsappTotalDMLList = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_whatsapp_total_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, campaign} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_WHATSAPP_TOTAL_DML_LIST_API,
        null,
        certificate,
        {locationId: locationId, campaign: campaign},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetWhatsappStatusDMLList = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_whatsapp_status_dml_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, campaign, status} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_WHATSAPP_STATUS_DML_LIST_API,
        null,
        certificate,
        {locationId: locationId, campaign: campaign, status: status},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetWhatsappChartData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_whatsapp_chart_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, campaign} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_WHATSAPP_CHART_DATA,
        null,
        certificate,
        {locationId: locationId, campaign: campaign},
        signal,
      );
    },
    ...options,
  });
};
