import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_JOINT_VISIT_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const JointVisitReport = lazy(() =>
  import('../../../screens/JointVisitReport')
    .then(module => ({default: module.JointVisitReport})));

const JointVisitReportNavigation = [
  {
    path: ROUTE_JOINT_VISIT_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <JointVisitReport />
      </Suspense>
    ),
  },
];

export default JointVisitReportNavigation;
