import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_STOCKIST_API,
  RQ_ENABLE_STOCKIST_API,
  RQ_GET_SEARCH_STOCKIST_SECONDARY_LIST_BY_LOCATION_ID_AND_MONTH_API,
  RQ_GET_SECONDARY_SALES_MASTER_DETAILS_BY_DIVISION_ID_STOCKIST_ID_MONTH_API,
  RQ_GET_SECONDARY_SALES_MASTER_ZERO_CLOSING_LIST_BY_LOCATION_ID_MONTH_API,
  RQ_GET_STATUS_BY_TYPE_API,
  RQ_GET_STOCKIST_DATA_BY_CODE_API,
  RQ_GET_STOCKIST_FIND_BY_ID,
  RQ_SECONDARY_SALES_MASTER_UPDATE_STATUS_BY_MASTER_ID_STATUS_API,
  RQ_SUBMIT_SECONDARY_SALES_MASTER_API,
  RQ_UPDATE_STOCKIST_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants/rqStockistAPIConstant';

export const RQUseGetStockistDataByName = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_stockist_data_by_name', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, code, name} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_STOCKIST_DATA_BY_CODE_API,
        null,
        certificate,
        {code: code, name: name},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetStatusByType = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_status_by_type', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, type} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_STATUS_BY_TYPE_API,
        null,
        certificate,
        {type : type},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutCreateStockist = ({stockist, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_STOCKIST_API,
    JSON.stringify({...stockist}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetStockistFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_stockist_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_STOCKIST_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutStockistUpdateAllField = async ({
  stockist,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_STOCKIST_ALL_FIELDS_API,
    JSON.stringify({...stockist, id:id}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetSearchStockistSecondaryListByLocationIdAndMonth = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_search_stockist_secondary_list_by_location_id_and_month', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, yearMonth} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SEARCH_STOCKIST_SECONDARY_LIST_BY_LOCATION_ID_AND_MONTH_API,
        null,
        certificate,
        {
          locationId: locationId,
          yearMonth: yearMonth,
        },
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetSecondarySalesMasterDetailsByDivisionIdStockistTDMonth = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_secondary_sales_master_details_by_division_id_stockist_id_month', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, stockistId, yearMonth} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SECONDARY_SALES_MASTER_DETAILS_BY_DIVISION_ID_STOCKIST_ID_MONTH_API,
        null,
        certificate,
        {
          divisionId: divisionId,
          stockistId: stockistId,
          yearMonth: yearMonth,
        },
        signal,
      );
    },
    ...options,
  });
};

export const RQPOSTSubmitSecondarySalesMater = ({secondarySales, certificate, signal}) => {
  return rqCreateRequest(
    RQ_SUBMIT_SECONDARY_SALES_MASTER_API,
    JSON.stringify({...secondarySales}),
    certificate,
    null,
    signal,
  );
};

export const RQPutSecondarySaleMasterUpdateStatusByMasterIdStatus = ({masterId, status, certificate, signal}) => {
  return rqCreateRequest(
    RQ_SECONDARY_SALES_MASTER_UPDATE_STATUS_BY_MASTER_ID_STATUS_API,
    JSON.stringify({masterId, status}),
    certificate,
    {masterId, status},
    signal,
  );
};

export const RQUseGetSecondarySalesMasterZeroClosingListByLocationIdMonth = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_secondary_sales_master_zero_closing_list_by_location_id_month', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, yearMonth} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SECONDARY_SALES_MASTER_ZERO_CLOSING_LIST_BY_LOCATION_ID_MONTH_API,
        null,
        certificate,
        {
          locationId: locationId,
          yearMonth: yearMonth,
        },
        signal,
      );
    },
    ...options,
  });
};

export const RQPutStockistEnableStockist = ({employeeCode, stockistId, yyyyMm, certificate, signal}) => {
  return rqCreateRequest(
    RQ_ENABLE_STOCKIST_API,
    null,
    certificate,
    {employeeCode: employeeCode, stockistId :stockistId, yyyyMm: yyyyMm},
    signal,
  );
};
