import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_BRAND, ROUTE_BRAND, ROUTE_UPDATE_BRAND} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Brand = lazy(() =>
  import('../../../screens/Brand')
    .then(module => ({default: module.Brand})));

const AddBrand = lazy(() =>
  import('../../../screens/Brand')
    .then(module => ({default: module.CreateBrand})));

const EditBrand = lazy(() =>
  import('../../../screens/Brand')
    .then(module => ({default: module.EditBrand})));

const BrandNavigation = [
  {
    path: ROUTE_BRAND,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Brand />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_BRAND,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddBrand />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_BRAND,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditBrand />
      </Suspense>
    ),
  },
];

export default BrandNavigation;
