import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_SPECIALITY_COVERAGE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const SpecialityCoverageReport = lazy(() => import('../../../screens/SpecialityCoverageReport')
  .then(module => ({default: module.SpecialityCOverageReport})));

const SpecialityCoverageReportNavigation = [
  {
    path: ROUTE_SPECIALITY_COVERAGE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SpecialityCoverageReport />
      </Suspense>
    ),
  },
];

export default SpecialityCoverageReportNavigation;
