import {redirect} from 'react-router-dom';
import {ROUTE_BASE_ROOT, ROUTE_DASHBOARD} from '../RoutePathConstant';
import {getLocalAuth} from '@app/localStorage';

export const loginLoader = async () => {
  const auth = await getLocalAuth();

  if (auth) {
    // return redirect(ROUTE_BASE_ROOT + ROUTE_LOCATION);
    return redirect(ROUTE_BASE_ROOT + ROUTE_DASHBOARD);
  }
  return auth;
};
