import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_CREATE_BEAT_API, RQ_GET_BEATS_BY_LOCATION_ID_API, RQ_GET_BEAT_BY_ID_API} from '@app/reactQuery/apis/constants';

export const RQGetBeatsByLocationId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_beats_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BEATS_BY_LOCATION_ID_API,
        null,
        certificate,
        {locationId:locationId},
        signal,
      );
    },
    ...options,
  });
};

export const RQGetBeatById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_beats_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, beatId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BEAT_BY_ID_API,
        null,
        certificate,
        {beatId: beatId},
        signal,
      );
    },
    ...options,
  });
};

export const RQPostSaveBeat = ({beat, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_BEAT_API,
    JSON.stringify({...beat}),
    certificate,
    null,
    signal,
  );
};
