export const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
};

export const formatNumber = (number, precision) => {
  const num = isNaN(number) || number === null ? 0.00 : number;
  return num.toFixed(precision);
};
