import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_BRAND_DETAILING_APL} from '@app/reactQuery/apis/constants/rqBrandDetailingAPIConstant';

export const RQUseGetBrandDetailingData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_brand_detaling_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, startDate, endDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BRAND_DETAILING_APL,
        null,
        certificate,
        {
          locationId: locationId,
          fromYearMonthDate: startDate,
          toYearMonthDate: endDate,
        },
        signal,
      );
    },
    ...options,
  });
};
