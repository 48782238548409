export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const RESET_AUTH_DATA = 'RESET_AUTH_DATA';

export const IDENTIFY_AUTH_EXIST_ON_DEVICE_START =
  'IDENTIFY_AUTH_EXIST_ON_DEVICE_START';
export const IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL =
  'IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL';
export const IDENTIFY_AUTH_EXIST_ON_DEVICE_FAIL =
  'IDENTIFY_AUTH_EXIST_ON_DEVICE_FAIL';
export const ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE =
  'ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE';

export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESSFUL = 'GET_USER_PROFILE_SUCCESSFUL';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const UPDATE_USER_PROFILE_START = 'UPDATE_USER_PROFILE_START';
export const UPDATE_USER_PROFILE_SUCCESSFUL =
  'UPDATE_USER_PROFILE_SUCCESSFUL';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESSFUL = 'FORGOT_PASSWORD_SUCCESSFUL';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const CHANGE_PASSWORD_SUCCESSFUL = 'CHANGE_PASSWORD_SUCCESSFUL';

export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
