export const RQ_GET_CHEMIST_APPROVAL_LIST_API = {
  url: 'chemist/approval-list/<%= employeeId %>/<%= divisionId %>',
  method: 'GET',
  auth: true,
};

export const RQ_PUT_CHEMIST_APPROVAL_BY_CHEMIST_ID = {
  url: 'chemist/approve/<%= chemistId  %>/<%= managerId  %>/approve',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_CHEMIST_REJECT_BY_CHEMIST_ID = {
  url: 'chemist/approve/<%= chemistId  %>/<%= managerId  %>/reject',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_CHEMIST_FIND_BY_CHEMIST_ID = {
  url: 'chemist/restore/<%= chemistId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_CHEMIST_CUSTOMER_TYPE_BY_TYPE_API = {
  url:'systemlov/by-type/<%= type  %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_CHEMIST_TYPE_BY_TYPE_API = {
  url:'systemlov/by-type/<%= type  %>',
  method:'GET',
  auth:true,
};
