import React, {Suspense, lazy} from 'react';
import {ROUTE_WEBINAR_DASHBOARD} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const WebinarDashboard = lazy(() =>
  import('../../../screens/WebinarDashboard')
    .then(module => ({default: module.WebinarDashboard})));

const WebinarDashboardNavigation = [
  {
    path: ROUTE_WEBINAR_DASHBOARD,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <WebinarDashboard />
      </Suspense>
    ),
  },
];

export default WebinarDashboardNavigation;
