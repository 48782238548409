import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_UNLOCKING_HISTORY} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const UnlockingHistory = lazy(() =>
  import('../../../screens/UnlockingHistory')
    .then(module => ({default: module.UnlockingHistory})));

const UnlockingHistoryNavigation = [
  {
    path: ROUTE_UNLOCKING_HISTORY,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UnlockingHistory />
      </Suspense>
    ),
  },
];

export default UnlockingHistoryNavigation;
