import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_JOBROLE, ROUTE_EDIT_JOBROLE, ROUTE_JOBROLE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const JobRole = lazy(() =>
  import('../../../screens/JobRole')
    .then(module => ({default: module.JobRole})));

const AddJobRole = lazy(() =>
  import('../../../screens/JobRole')
    .then(module => ({default: module.AddJobRole})));

const EditJobRole = lazy(() =>
  import('../../../screens/JobRole')
    .then(module => ({default: module.EditJobRole})));

const JobRoleNavigation = [
  {
    path: ROUTE_JOBROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <JobRole />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_JOBROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddJobRole />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_JOBROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditJobRole />
      </Suspense>
    ),
  },
];

export default JobRoleNavigation;
